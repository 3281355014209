import React, { ReactNode } from 'react';
import { Text } from '../../components/Text';
import { Colors } from '../../configs/colors';



export const SubscriberDataErrors: React.FC = () => {
    return (
        <div className="first-aid-items">
            <div className="card card-table">
                <div className="card-body">
                    <div className="seed-data-group">
                        <Text fontSize={14} fontWeight="700" color={Colors.LSNavy}>
                            Invalid password. If you are unable to access, kindly reach out to the tech team for assistance
                        </Text>
                        
                    </div>
                   
                </div>
            </div>
        </div>
    );
};
