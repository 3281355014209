import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getEventsFromSubscriberId, getEventsFromCorrelationId, getEventsFromDateRange } from '../api/platform';
import { getSubscriberByPhoneNumber } from '../api/subscribers';
import { InspectorData } from '../pages/Inspector/Inspector.props';
import { getInspectorInEvents, sortInspectorData } from '../utils/dataPreparationHelper';
import { formatDate } from '../utils/dateTimeHelper';
import { RootState } from './store';

interface SearchEventsParams {
  subscriberId?: string,
  correlationId?: string;
  dateRange?: DateRange;
}
interface DateRange {
  from: string;
  to: string;
}
export interface InspectorEventsObjectState {
  data?: InspectorData[];
  isLoading: boolean;
  error?: string;
  status?: string;
  message?: string;
}

const initialState: InspectorEventsObjectState = {
  data: undefined,
  isLoading: false,
  error: undefined,
  status: undefined,
  message: undefined,
};

export const subscriberDetailsByPhoneNumber = createAsyncThunk('subscriberDetails/ByPhone', async (phoneNumber: string) => {
  const response = await getSubscriberByPhoneNumber(phoneNumber);
  const data = response.lifestreamResponse.data;
  return {
    subscriberId: data.subscriberId,
  }
})

export const inspectorEvents = createAsyncThunk('inspectorEventPlatform', async (searchEventsParams: SearchEventsParams) => {
  let response;
  if (searchEventsParams.subscriberId) {
    response = await getEventsFromSubscriberId(searchEventsParams.subscriberId);
  }
  if (searchEventsParams.correlationId) {
    response = await getEventsFromCorrelationId(searchEventsParams.correlationId);
  }
  if (searchEventsParams.dateRange) {
    response = await getEventsFromDateRange(searchEventsParams.dateRange);
  }

  const data = response.lifestreamResponse.data;
  return data ? sortInspectorData(data.map((item: any) => {
    return {
      id: item.id,
      category: item.category,
      companyId: item.companyId,
      createdDate: formatDate(item.createdDate, 'YYYY-MM-DD HH:mm:ss'),
      startDate: item.createdDate,
      message: item.message,
      events: getInspectorInEvents(item.events),
      detailEvent: item.data,
      correlationId: item.correlationId
    }
  }), 'desc') : [];
});

const inspectorSlice = createSlice({
  name: 'inspectorEvents',
  initialState,
  reducers: {
    setInspectorEventData: (state, action: PayloadAction<any | undefined>) => {
      state.isLoading = false;
      state.data = action.payload.data as InspectorData[];
      state.error = undefined;
    },
    setClearInspectorEventData: (state) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = undefined;
      state.status = undefined;
      state.message = undefined;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(inspectorEvents.pending, (state) => {
      state.isLoading = true;
      state.status = undefined;
      state.message = undefined;
      state.data = undefined;
    });

    builder.addCase(inspectorEvents.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload as InspectorData[];
      state.error = undefined;
      state.status = 'success';
      state.message = action.payload.length > 0 ? 'Search successfully' : 'No data available';
    });

    builder.addCase(inspectorEvents.rejected, (state, action) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = action.payload as string;
      state.status = 'error';
      state.message = 'Search error';
    });

    builder.addCase(subscriberDetailsByPhoneNumber.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(subscriberDetailsByPhoneNumber.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(subscriberDetailsByPhoneNumber.rejected, (state, action) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = action.payload as string;
    });
  },
});

export const {
  setInspectorEventData,
  setClearInspectorEventData,
} = inspectorSlice.actions;

export const inspectorEventsDataSelector = (state: RootState) => state.inspectorEvents;

export default inspectorSlice.reducer;
