/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { emergencyNumbers, emergencyNumbersSelector } from '../../redux/emergencyNumbersSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { Text } from '../../components/Text';
import { Colors } from '../../configs/colors';

interface EmergencyNumbersProps {}

export const EmergencyNumbers: React.FC<EmergencyNumbersProps> = () => {
  const dispatch = useAppDispatch();
  const emergencyNumbersData = useAppSelector(emergencyNumbersSelector);

  useEffect(() => {
    dispatch(emergencyNumbers());
  }, []);
  return (
    <div className="emergency-numbers">
      <div className="card card-table">
        <div className="card-body">
          {emergencyNumbersData.data &&
            emergencyNumbersData.data.length > 0 &&
            emergencyNumbersData.data.map((res) => (
              <div key={res.country}>
                <Text fontSize={24} fontWeight="700" color={Colors.LSNavy}>
                  Country: {res.country}
                </Text>
                <Text fontSize={24} fontWeight="700" color={Colors.LSNavy}>
                  Primary Number: {res.primaryNumber}
                </Text>
                {res.emergencyNumbers.length > 0 &&
                  res.emergencyNumbers.map((emergency) => (
                    <div key={emergency.name} className="card-title-group">
                      <img src={emergency?.image} alt="" />
                      <Text fontSize={24} fontWeight="700" color={Colors.LSNavy}>
                        {emergency.name}
                      </Text>
                      <Text fontSize={14} fontWeight="600" color={Colors.LSNavy}>
                        {emergency.description}
                      </Text>
                    </div>
                  ))}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
