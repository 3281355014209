import { MenuData } from "../redux/sidebarSlice";

export const removeActiveMenu = (menuData: MenuData[]) => {
  menuData.map((item) => {
    const elms = document.getElementById(item.id);
    if (!elms?.children[0].className.includes('collapsed')) {
      elms?.children[0].classList.add('collapsed');
    }
    elms?.children[1].classList.remove('show');
    item.childMenu.map((child) => {
      const elms = document.getElementById(child.id);
      elms?.classList.remove('active');
      return null;
    });
    return null;
  });
}
