import React from 'react'
import { Modal } from 'react-bootstrap'
import { images } from '../../constants/images';

const MessageBoxPopup = ({ show, headerTextColor, OnCloseClick, children, headerTest }: {
    show?: boolean,
    headerTextColor?: string,
    OnCloseClick: () => {},
    children?: React.ReactNode
    headerTest?: string
}) => {
    return (
        <Modal
            show={show}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header className='message-modal-header' style={{color: headerTextColor}}>
                <Modal.Title id="contained-modal-title-vcenter">
                    {headerTest}
                </Modal.Title>
                <div className="close">
                    <img width="24px" src={images.Close} onClick={OnCloseClick} alt="" />    
                </div>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
        </Modal>
    )
}

export default MessageBoxPopup;
