import React from 'react';
import './TableHeader.styles.css';
import { TableHeaderProps } from './TableHeader.props';

export const TableHeader: React.FC<TableHeaderProps> = ({ dataHeader }) => {
  return (
    <div className="table-header-style">
      {dataHeader.map((item, index) => (
        <div className="header-title" style={{ width: item.widthType}} key={index}>
          {item.type}
        </div>
      ))}
    </div>
  );
};
