import React, { useEffect, useState } from 'react';
import './ToastMessage.styles.css';

interface ToastMessageProps {
  position?: string;
  type: string;
  message: string;
  onHideToast?: (value: boolean) => void;
}

export const ToastMessage: React.FC<ToastMessageProps> = ({
  position = 'bottom-right',
  message,
  type = '',
  onHideToast 
}) => {
  const [show, setShow] = useState<boolean>(false);
  const [classToast, setClassToast] = useState<string>('');

  useEffect(() => {
    if (type) {
      setClassToast(type);
      setShow(true);
      setTimeout(() => {
        setShow(false);
        onHideToast?.(false)
      }, 3000);
    }
  }, [type, onHideToast]);

  const onToggleClose = () => {
    setShow(false);
  };

  return (
    <>
      {show && (
        <div className={`notification-container ${position}`}>
          <div className={`notification-toast ${position} toast-${classToast}`}>
            <div className="notification-message">{message}</div>
            <div className="notification-close" onClick={onToggleClose}>X</div>
          </div>
        </div>
      )}
    </>
  );
};
