import React, { useState } from 'react';
import { InputFieldProps, InputFieldVariant } from './InputField.props';
import './InputField.styles.css';
import Close from '../../assets/images/close.svg';

export const InputField: React.FC<InputFieldProps> = ({
  alt = '',
  type,
  border,
  value = '',
  boxShadow,
  placeholder,
  width = '100%',
  height = '32px',
  onChange,
  variant = InputFieldVariant.General,
  label = '',
  labelColor = '#000000',
  Icon = null,
  disabled = false,
  textColor = false,
  onKeyPress,
  onCheckFocus,
  closeIcon,
  onClearValue,
}) => {
  const [isShowLabel, setShowLabel] = useState<boolean>(false);
  const handleNoteOnFocus = (event: any) => {
    if (event.target.value === '') setShowLabel(true);
    onCheckFocus && onCheckFocus(true);
  };
  const handleNoteOnBlur = (event: any) => {
    if (event.target.value === '') setShowLabel(false);
    onCheckFocus && onCheckFocus(false);
  };

  const handleOnKeyPress = (event: any) => {
    onKeyPress && onKeyPress(event);
  };

  const handleClearValue = () => {
    onClearValue && onClearValue();
    setShowLabel(false);
  };

  switch (variant) {
    case InputFieldVariant.Labelled:
      return (
        <div className="input-labelled-wrap" style={{ width, border, boxShadow, height }}>
          {Icon && <Icon style={{ marginLeft: 15 }} stroke={labelColor} width={20} height={20} />}
          <div className="input-container">
            <input
              className={`input-style ${textColor ? 'text-color' : ''} ${
                isShowLabel ? 'input-filled' : ''
              }`}
              type={type}
              value={value}
              placeholder={!isShowLabel ? placeholder : ''}
              onChange={onChange}
              onFocus={handleNoteOnFocus}
              onBlur={handleNoteOnBlur}
              onKeyPress={handleOnKeyPress}
            />
            {isShowLabel && (
              <div className="filled">
                <div className="filled-label">{label}</div>
              </div>
            )}
            {closeIcon && (
              <div className="close-icon">
                <img onClick={handleClearValue} height="24px" width="24px" src={Close} alt="" />
              </div>
            )}
          </div>
        </div>
      );
    case InputFieldVariant.SearchBar:
      return (
        <div className="search-bar">
          <div className="search-form d-flex align-items-center">
            <button type="submit" title="Search">
              <i className="bi bi-search"></i>
            </button>
            <input
              readOnly={disabled}
              type={type}
              value={value}
              placeholder={placeholder}
              style={{ width, height }}
              onChange={onChange}
              onKeyPress={handleOnKeyPress}
              alt={alt}
            />
          </div>
        </div>
      );

    default:
      return (
        <input
          disabled={disabled}
          type={type}
          value={value}
          placeholder={placeholder}
          className={`input-style ${textColor ? 'text-color' : ''}`}
          style={{ width }}
          onChange={onChange}
          alt={alt}
        />
      );
  }
};
