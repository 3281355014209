import React from 'react';
import { images } from '../../constants/images';
import { DetailEvent } from '../../pages/Inspector/Inspector.props';
import { formatDate } from '../../utils/dateTimeHelper';
import './InspectorPopup.styles.css';

interface InspectorPopupProps {
  detailEvent?: DetailEvent;
  onClose: (value: boolean) => void;
  logReferenceId?: string;
}

export const InspectorPopup: React.FC<InspectorPopupProps> = ({
  detailEvent,
  onClose,
  logReferenceId,
}) => {
  return (
    <div className="event-popup">
      <div className="event-popup-content">
        <div className="popup-title">
          <div className="title">Events Detail</div>
          <div className="close">
            <img width="24px" src={images.Close} onClick={() => onClose(false)} alt="" />
          </div>
        </div>
        <div className="detail-data">
          {detailEvent?.id && (
            <div>
              <span className="label">Id:</span> {detailEvent?.id}
            </div>
          )}
          {detailEvent?.name && (
            <div>
              <span className="label">Name :</span> {detailEvent?.name}
            </div>
          )}
          {detailEvent?.createdDate && (
            <div>
              <span className="label">Created Date: </span>
              {formatDate(detailEvent?.createdDate, 'YYYY-MM-DD HH:mm:ss')}
            </div>
          )}
          {detailEvent?.updatedDate && (
            <div>
              <span className="label">Updated Date: </span>
              {formatDate(detailEvent?.updatedDate, 'YYYY-MM-DD HH:mm:ss')}
            </div>
          )}
          {logReferenceId && (
            <div>
              <span className="label">Log Reference Id: </span>
              {logReferenceId}
            </div>
          )}
          {detailEvent?.contactSubscriberId && (
            <div>
              <span className="label">Contact Subscriber Id: </span>
              {detailEvent?.contactSubscriberId}
            </div>
          )}
          {detailEvent?.inviteeSubscriberId && (
            <div>
              <span className="label">Invitee Subscriber Id: </span>
              {detailEvent?.inviteeSubscriberId}
            </div>
          )}
          {detailEvent?.memberSubscriberId && (
            <div>
              <span className="label">Member Subscriber Id: </span>
              {detailEvent?.memberSubscriberId}
            </div>
          )}
          {detailEvent?.groupId && (
            <div>
              <span className="label">Group Id: </span>
              {detailEvent?.groupId}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
