import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getReferenceData } from '../api/emergencyNumbers';
import { RootState } from './store';
import { getNumberOfGroupsById } from '../api/subscribers';

export interface SubscriberGroupDetails {
  numberOfGroups: number;
}
export interface SubscriberGroupDetailsState {
  data: SubscriberGroupDetails;
  isLoading: boolean;
  error?: string;
}

const initialState: SubscriberGroupDetailsState = {
  isLoading: false,
  data: {numberOfGroups:0},
  error: undefined,
}

export const getSubscriberGroupLength = createAsyncThunk('getSubscriberGroupLength', async (id: string) => {
  const response = await getNumberOfGroupsById(id);
  const data = response.lifestreamResponse.data;
  return data;

})

const subscriberGroupDetailsSlice = createSlice({
  name: 'subscriberGroupDetails',
  initialState,
  reducers: {
    subscriberGroupDetailsData: (state, action) => {
      state.isLoading = false;
      state.data = action.payload as SubscriberGroupDetails;
      state.error = undefined;
    },
    subscriberGroupDetailsClear: (state) => {
      state.isLoading = false;
      state.data = {numberOfGroups:0};
      state.error = undefined;
    },

  },
  extraReducers: (builder) => {
    builder.addCase(getSubscriberGroupLength.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getSubscriberGroupLength.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload as any;
      state.error = undefined;
    });

    builder.addCase(getSubscriberGroupLength.rejected, (state, action) => {
      state.isLoading = false;
      state.data = {numberOfGroups:0};
      state.error = action.payload as string;
    });
  },
});

export const {
  subscriberGroupDetailsData,
  subscriberGroupDetailsClear,
} = subscriberGroupDetailsSlice.actions;

export const subscriberGroupDetailsSelector = (state: RootState) => state.subscriberGroupDetails;

export default subscriberGroupDetailsSlice.reducer;