import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import { getProfileImage } from '../api/msGraph';
import {RootState} from './store';

export interface ProfilePictureObjectState {
  data?: any;
  isLoading: boolean;
  error?: string;
}

const initialState: ProfilePictureObjectState = {
  data: undefined,
  isLoading: false,
  error: undefined
};

export const msGraphProfilePicture = createAsyncThunk('msGraphProfilePicture', async () => {
  const response = await getProfileImage();
  return response;
});

const profilePictureSlice = createSlice({
  name: 'profilePicture',
  initialState,
  reducers: {
    setProfilePictureData: (state, action: PayloadAction<any | undefined>) => {
      state.isLoading = false;
      state.data = action.payload as any;
      state.error = undefined;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(msGraphProfilePicture.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(msGraphProfilePicture.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload as any;
      state.error = undefined;
    });

    builder.addCase(msGraphProfilePicture.rejected, (state, action) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = action.payload as string;
    });
  },
});

export const {
    setProfilePictureData
} = profilePictureSlice.actions;

export const profilePictureDataSelector = (state: RootState) => state.profilePicture;

export default profilePictureSlice.reducer;
