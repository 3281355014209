import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getPromoCodesDetail } from '../api/subscriptions';
import { PromoCodesDetailData } from "../pages/Dashboard/Dashboard.props";
import { RootState } from './store';

export interface PromoCodesDetailObjectState {
  data?: PromoCodesDetailData;
  isLoading: boolean;
  error?: string;
}

const initialState: PromoCodesDetailObjectState = {
  isLoading: false,
  data: undefined,
  error: undefined,
}

export const getPromoCodesDetailByCode = createAsyncThunk('promoCodesDetail/getPromoCodesDetail', async (code: string) => {
  const response = await getPromoCodesDetail(code);
  const dataResponse = response.lifestreamResponse.data;
  return {
    count: dataResponse.count ?? 0,
    promoCodesByIdData: dataResponse.data ?? []
  }
})

const promoCodesDetailSlice = createSlice({
  name: 'promoCodesDetail',
  initialState,
  reducers: {
    setPromoCodesDetailData: (state, action: PayloadAction<PromoCodesDetailData>) => {
      state.isLoading = false;
      state.data = action.payload;
      state.error = undefined;
    },
    setPromoCodesDetailClear: (state) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = undefined;
    },

  },
  extraReducers: (builder) => {
    builder.addCase(getPromoCodesDetailByCode.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getPromoCodesDetailByCode.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload as any;
      state.error = undefined;
    });

    builder.addCase(getPromoCodesDetailByCode.rejected, (state, action) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = action.payload as string;
    });
  },
});

export const {
  setPromoCodesDetailData,
  setPromoCodesDetailClear,
} = promoCodesDetailSlice.actions;

export const promoCodesDetailSelector = (state: RootState) => state.promoCodesDetail;

export default promoCodesDetailSlice.reducer;
