import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getSubscriptions } from '../api/subscriptions';
import { RootState } from './store';

export interface SubscriptionsObjectState {
  data?: SubscriptionsData[];
  isLoading: boolean;
  error?: string;
}

export interface SubscriptionsFeatures {
  description : string;
  display: boolean;
  displayOrder:number;
  featureCode: string;
  featureId: string;
  featureName : string;
  image : string;
  subscriptionId : string;
}

export interface SubscriptionsDefaultPrice {
  currency: string;
  priceId:string;
  recurringInterval:string;
  type:string;
  unitAmount:number;
  unitAmountFormatted:string;
}

export interface SubscriptionsData {
  shortName: string,
  trialPeriodInDays: string,
  subscriptionId: string;
  description: string;
  isBasic: boolean;
  isDefault: boolean;
  messageTemplate: string
  name: string;
  type: string;
  features: SubscriptionsFeatures[];
  defaultPrice: SubscriptionsDefaultPrice;
}

const initialState: SubscriptionsObjectState = {
  isLoading: false,
  data: undefined,
  error: undefined,
}

export const getSubscriptionsBySubs = createAsyncThunk('subscriptions/getSubscriptions', async () => {
  const response = await getSubscriptions();
  const data = response.lifestreamResponse.data;
  return data ? data.subscriptions : [];
})

const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    setSubscriptionsData: (state, action: PayloadAction<SubscriptionsData[]>) => {
      state.isLoading = false;
      state.data = action.payload;
      state.error = undefined;
    },
    setSubscriptionsClear: (state) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = undefined;
    },

  },
  extraReducers: (builder) => {
    builder.addCase(getSubscriptionsBySubs.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getSubscriptionsBySubs.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload as any;
      state.error = undefined;
    });

    builder.addCase(getSubscriptionsBySubs.rejected, (state, action) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = action.error.message as string;
    });
  },
});

export const {
  setSubscriptionsData,
  setSubscriptionsClear,
} = subscriptionsSlice.actions;

export const subscriptionsSelector = (state: RootState) => state.subscriptions;

export default subscriptionsSlice.reducer;