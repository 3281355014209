import React from 'react';
import './UserProfile.styles.css';
import { useMsal } from '@azure/msal-react';
import { Overview } from './Overview';
import { EditProfile } from './EditProfile';
import { CONSTANTS } from '../../constants/constants';
import { images } from '../../constants/images';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  setShowTabOverview,
  UserProfileSelector,
  setShowTabSetting,
  setShowTabEditProfile,
  setShowTabChangePass,
} from '../../redux/userProfileSlice';

type UserProfileProps = {};

export const UserProfile: React.FC<UserProfileProps> = () => {
  const { accounts } = useMsal();
  const accountName = accounts[0] && accounts[0].name;
  const accountEmail = accounts[0] && accounts[0].username;
  const { isChangePass, isEditProfile, isOverview, isSetting } =
    useAppSelector(UserProfileSelector);
  const dispatch = useAppDispatch();

  const toggleNavItems = (type: string) => {
    switch (type) {
      case CONSTANTS.PROFILE.TYPE.OVERVIEW:
        dispatch(setShowTabOverview());
        break;
      case CONSTANTS.PROFILE.TYPE.CHANGE_PASSWORD:
        dispatch(setShowTabChangePass());
        break;
      case CONSTANTS.PROFILE.TYPE.EDIT:
        dispatch(setShowTabEditProfile());
        break;
      case CONSTANTS.PROFILE.TYPE.SETTING:
        dispatch(setShowTabSetting());
        break;

      default:
        break;
    }
  };

  return (
    <div className="section profile">
      <div className="row">
        <div className="col-xl-4">
          <div className="card">
            <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
              <img src={images.DefaultImageProfile} alt="Profile" className="rounded-circle" />
              <h2>{accountName}</h2>
              <h3>{accountEmail}</h3>
              <div className="social-links mt-2 d-flex">
                <div className="twitter">
                  <i className="bi bi-twitter"></i>
                </div>
                <div className="facebook">
                  <i className="bi bi-facebook"></i>
                </div>
                <div className="instagram">
                  <i className="bi bi-instagram"></i>
                </div>
                <div className="linkedin">
                  <i className="bi bi-linkedin"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-8">
          <div className="card">
            <div className="card-body pt-3">
              <ul className="nav nav-tabs nav-tabs-bordered">
                <li className="nav-item">
                  <div
                    className={`nav-link ${isOverview ? 'active' : ''}`}
                    onClick={() => toggleNavItems(CONSTANTS.PROFILE.TYPE.OVERVIEW)}
                  >
                    {CONSTANTS.PROFILE.TITLE.OVERVIEW}
                  </div>
                </li>

                <li className="nav-item">
                  <div
                    className={`nav-link ${isEditProfile ? 'active' : ''}`}
                    onClick={() => toggleNavItems(CONSTANTS.PROFILE.TYPE.EDIT)}
                  >
                    {CONSTANTS.PROFILE.TITLE.EDIT}
                  </div>
                </li>

                <li className="nav-item">
                  <div
                    className={`nav-link ${isSetting ? 'active' : ''}`}
                    onClick={() => toggleNavItems(CONSTANTS.PROFILE.TYPE.SETTING)}
                  >
                    {CONSTANTS.PROFILE.TITLE.SETTING}
                  </div>
                </li>

                <li className="nav-item">
                  <div
                    className={`nav-link ${isChangePass ? 'active' : ''}`}
                    onClick={() => toggleNavItems(CONSTANTS.PROFILE.TYPE.CHANGE_PASSWORD)}
                  >
                    {CONSTANTS.PROFILE.TITLE.CHANGE_PASSWORD}
                  </div>
                </li>
              </ul>
              <div className="tab-content pt-2">
                {isOverview && <Overview />}
                {isEditProfile && <EditProfile />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
