import { configureStore } from '@reduxjs/toolkit';
import sidebarReducer from './sidebarSlice';
import userProfileReducer from './userProfileSlice';
import profilePictureReducer from './profilePictureSlice';
import inspectorEventsReducer from './inspectorSlice';
import subscriberDetailsReducer from './subscriberDetailsSlice';
import emergencyNumbersReducer from './emergencyNumbersSlice';
import firstAidItemsReducer from './firstAidItemsSlice';
import subscriptionsReducer from './subscriptionsSlice';
import referenceReducer from './referenceSlice';
import subscriptionReducer from './subscriptionSlice';
import promoCodesReducer from "./promoCodesSlice";
import promoCodesDetailReducer from "./promoCodesDetailSlice";
import couponsListReducer from './couponsListSlice'
import seedPromoCodeReducer from './seedPromoCodeSlice';
import subscriberSearchReducer from './subscriberSearchSlice'
import deactiveAccountReducer from './deactiveSubscriberSlice'
import promoCodeListReducer from './promoCodeListSlice';
import membershipListForPromocodeReducer from './membershipsForPromocodeSlice';
import protectedDataPasswordReducer from './protectedDataPasswordSlice';
import subscriberGroupDetailsReducer from './subscriberGroupDetailsSlice';

export const store = configureStore({
  reducer: {
    sidebar: sidebarReducer,
    userProfile: userProfileReducer,
    profilePicture: profilePictureReducer,
    inspectorEvents: inspectorEventsReducer,
    subscriberDetails: subscriberDetailsReducer,
    emergencyNumbers: emergencyNumbersReducer,
    firstAidItems: firstAidItemsReducer,
    subscriptions: subscriptionsReducer,
    reference: referenceReducer,
    subscription: subscriptionReducer,
    promoCodes: promoCodesReducer,
    promoCodesDetail: promoCodesDetailReducer,
    // promoCodesList: promoCodesListSlice,
    couponsList: couponsListReducer,
    seedPromoCodeData: seedPromoCodeReducer,
    subscriberSearchData: subscriberSearchReducer,
    deactiveAccount: deactiveAccountReducer,
    promoCodeList: promoCodeListReducer,
    membershipListForPromocode: membershipListForPromocodeReducer,
    protectedDataPassword: protectedDataPasswordReducer,
    subscriberGroupDetails:subscriberGroupDetailsReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
