/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import './Header.css';
import { useMsal } from '@azure/msal-react';
import appConfig from '../configs/appConfig.json';
import { useNavigate } from 'react-router-dom';
import { images } from '../constants/images';
import { useAppDispatch } from '../redux/hooks';
import { setBreadcrumb, setToggleSidebar } from '../redux/sidebarSlice';
import { msGraphProfilePicture } from '../redux/profilePictureSlice';
import { CONSTANTS } from '../constants/constants';

interface HeaderProps {}

export const Header: React.FC<HeaderProps> = () => {
  const refEvent = useRef(null);
  const refProfileEvent = useRef(null);
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const [isToggleNotification, setToggleNotification] = useState<boolean>(false);
  const [isToggleProfile, setToggleProfile] = useState<boolean>(false);
  const accountName = accounts[0] && accounts[0].name;
  const accountEmail = accounts[0] && accounts[0].username;
  const dispatch = useAppDispatch();

  const toggleSidebar = () => {
    dispatch(setToggleSidebar());
  };

  const toggleNotification = () => {
    setToggleNotification(!isToggleNotification);
  };

  const toggleProfile = () => {
    setToggleProfile(!isToggleProfile);
  };

  const toggleMyProfile = () => {
    navigate('/user-profile');
    dispatch(setBreadcrumb(CONSTANTS.SIDEBAR.BREADCRUMB.USER_PROFILE));
    setToggleProfile(!isToggleProfile);
  };

  const handleClickOutSide = (event: any) => {
    //@ts-ignore
    if (refEvent.current && !refEvent.current.contains(event.target)) {
      setToggleNotification(false);
    }
    //@ts-ignore
    if (refProfileEvent.current && !refProfileEvent.current.contains(event.target)) {
      setToggleProfile(false);
    }
  };

  const handleSignOut = async () => {
    try {
      localStorage.clear();
      const logingIdClaims = accounts[0].idTokenClaims as any;
      await instance.logoutRedirect({
        authority: appConfig.logoutRequest.authority,
        account: accounts[0],
        postLogoutRedirectUri: appConfig.logoutRequest.postLogoutRedirectUri,
        logoutHint: logingIdClaims.login_hint,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    //TODO: this will we covered in a future task
    // dispatch(msGraphProfilePicture()).then((profilePicture) => {
    //   console.log('...........................................');
    //   console.log(profilePicture);
    // });
    document.addEventListener('click', handleClickOutSide, false);
    return () => {
      document.addEventListener('click', handleClickOutSide, false);
    };
  }, []);

  return (
    <div>
      <header id="header" className="header fixed-top">
        <div className="d-flex align-items-center justify-content-between">
          <div className="logo d-flex align-items-center">
            <img src={images.Logo} alt="" />
          </div>
          <i className="bi bi-list toggle-sidebar-btn" onClick={toggleSidebar}></i>
        </div>

        <nav className="header-nav ms-auto">
          <ul className="d-flex align-items-center">
            <li className="nav-item dropdown" ref={refEvent}>
              <div className="nav-link nav-icon mr-0" onClick={toggleNotification}>
                <i className="bi bi-bell"></i>
                <span className="badge bg-primary badge-number">4</span>
              </div>
              {isToggleNotification && (
                <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">
                  <li className="dropdown-header">
                    You have 4 new notifications
                    <div>
                      <span className="badge rounded-pill bg-primary p-2 ms-2">View all</span>
                    </div>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li className="notification-item">
                    <i className="bi bi-exclamation-circle text-warning"></i>
                    <div>
                      <h4>Lorem Ipsum</h4>
                      <p>Quae dolorem earum veritatis oditseno</p>
                      <p>30 min. ago</p>
                    </div>
                  </li>

                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li className="notification-item">
                    <i className="bi bi-x-circle text-danger"></i>
                    <div>
                      <h4>Atque rerum nesciunt</h4>
                      <p>Quae dolorem earum veritatis oditseno</p>
                      <p>1 hr. ago</p>
                    </div>
                  </li>

                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li className="notification-item">
                    <i className="bi bi-check-circle text-success"></i>
                    <div>
                      <h4>Sit rerum fuga</h4>
                      <p>Quae dolorem earum veritatis oditseno</p>
                      <p>2 hrs. ago</p>
                    </div>
                  </li>

                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li className="notification-item">
                    <i className="bi bi-info-circle text-primary"></i>
                    <div>
                      <h4>Dicta reprehenderit</h4>
                      <p>Quae dolorem earum veritatis oditseno</p>
                      <p>4 hrs. ago</p>
                    </div>
                  </li>

                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li className="dropdown-footer">
                    <div>Show all notifications</div>
                  </li>
                </ul>
              )}
            </li>

            <li className="nav-item dropdown" ref={refProfileEvent}>
              <div
                onClick={toggleProfile}
                className="nav-link nav-profile d-flex align-items-center pe-0"
                data-bs-toggle="dropdown"
              >
                <img
                  src={images.DefaultImageProfile}
                  alt="Profile"
                  className="rounded-circle"
                  style={{ width: '36px', height: '36px' }}
                />
                <span className="d-none d-md-block dropdown-toggle pl-2">{accountName}</span>
              </div>
              {isToggleProfile && (
                <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                  <li className="dropdown-header">
                    <h6>{accountName}</h6>
                    <span>{accountEmail}</span>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <div
                      className="dropdown-item d-flex align-items-center"
                      onClick={toggleMyProfile}
                    >
                      <i className="bi bi-person"></i>
                      <span>My Profile</span>
                    </div>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a
                      className="dropdown-item d-flex align-items-center"
                      href="users-profile.html"
                    >
                      <i className="bi bi-gear"></i>
                      <span>Account Settings</span>
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a className="dropdown-item d-flex align-items-center" href="pages-faq.html">
                      <i className="bi bi-question-circle"></i>
                      <span>Need Help?</span>
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <div
                      className="dropdown-item d-flex align-items-center"
                      onClick={handleSignOut}
                    >
                      <i className="bi bi-box-arrow-right"></i>
                      <span>Sign Out</span>
                    </div>
                  </li>
                </ul>
              )}
            </li>
          </ul>
        </nav>
      </header>
    </div>
  );
};
