import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { seedPromoCode } from '../api/subscriptions';
import { RootState } from './store';

export interface SeedPromoCodeObjectState {
  data?: null;
  isLoading: boolean;
  error?: string | null;
}

export interface SeedPromoCodeFeatures {
  description : string;
  display: boolean;
  displayOrder:number;
  featureCode: string;
  featureId: string;
  featureName : string;
  image : string;
  subscriptionId : string;
}

export interface SeedPromoCodeDefaultPrice {
  currency: string;
  priceId:string;
  recurringInterval:string;
  type:string;
  unitAmount:number;
  unitAmountFormatted:string;
}

const initialState: SeedPromoCodeObjectState = {
  isLoading: false,
  data: null,
  error: null,
}

export const postSeedPromoCode = createAsyncThunk('subscriptions/seedPromoCode', async (data: {}) => {  
  try {
    const response = await seedPromoCode(data);
    const responseData = response.lifestreamResponse;
    return responseData ? responseData : [];
  } catch (error) {
    throw error;
  }
})

const seedPromoCodeSlice = createSlice({
  name: 'subscriptions',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(postSeedPromoCode.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(postSeedPromoCode.fulfilled, (state, action) => {      
      state.isLoading = false;
      state.data = action.payload as any;
      state.error = null;
    });

    builder.addCase(postSeedPromoCode.rejected, (state, action) => {        
      state.isLoading = false;
      state.data = null;
      state.error = action.error.message as string;
    });
  },
});


export const seedPromoCodeSelector = (state: RootState) => state.seedPromoCodeData;

export default seedPromoCodeSlice.reducer;