import React from 'react';
import { images } from '../../constants/images';
import { formatDate } from '../../utils/dateTimeHelper';
import { EnrollmentDetailData } from '../../pages/Dashboard/Dashboard.props';
import './EnrollmentDetailPopup.styles.css';

interface PromoCodesPopupProps {
  detailEvent?: EnrollmentDetailData;
  onClose: (value: boolean) => void;
}

export const EnrollmentDetailPopup: React.FC<PromoCodesPopupProps> = ({ detailEvent, onClose }) => {
  return (
    <div className="event-popup">
      <div className="event-popup-content">
        <div className="popup-title">
          <div className="title">Enrollments Detail</div>
          <div className="close">
            <img width="24px" src={images.Close} onClick={() => onClose(false)} alt="" />
          </div>
        </div>
        <div className="detail-data">
          {detailEvent?.promoCodeId && (
            <div>
              <span className="label">PromoCode Id: </span> {detailEvent.promoCodeId}
            </div>
          )}
          {detailEvent?.subscriptionId && (
            <div>
              <span className="label">Subscription Id: </span> {detailEvent.subscriptionId}
            </div>
          )}
          {detailEvent?.subscriptionId && (
            <div>
              <span className="label">Subscriber Id: </span> {detailEvent.subscriptionId}
            </div>
          )}
          {detailEvent?.enrollmentId && (
            <div>
              <span className="label">Enrollment Id: </span> {detailEvent.enrollmentId}
            </div>
          )}
          {detailEvent?.nextBillingDate && (
            <div>
              <span className="label">Next Billing Date: </span>
              {formatDate(detailEvent?.nextBillingDate, 'YYYY-MM-DD HH:mm:ss')}
            </div>
          )}
          {detailEvent?.stripeStatus && (
            <div>
              <span className="label">Stripe Status: </span> {detailEvent.stripeStatus}
            </div>
          )}
          {detailEvent?.paymentMode && (
            <div>
              <span className="label">Payment Mode: </span> {detailEvent.paymentMode}
            </div>
          )}
          {detailEvent?.createdAt && (
            <div>
              <span className="label">Created At: </span>
              {formatDate(detailEvent?.createdAt, 'YYYY-MM-DD HH:mm:ss')}
            </div>
          )}
          {detailEvent?.updatedAt && (
            <div>
              <span className="label">Updated At: </span>
              {formatDate(detailEvent.updatedAt, 'YYYY-MM-DD HH:mm:ss')}
            </div>
          )}
          {detailEvent?.companyId && (
            <div>
              <span className="label">Company Id: </span> {detailEvent.companyId}
            </div>
          )}
          {detailEvent?.enrollmentId && (
            <div>
              <span className="label">Enrollment Id: </span> {detailEvent.enrollmentId}
            </div>
          )}
          {detailEvent?.status && (
            <div>
              <span className="label">Status: </span> {detailEvent.status}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
