import { CONSTANTS } from './../constants/constants';
import { EmergencyNumbersData } from '../redux/emergencyNumbersSlice';
import { FirstAidItemsRes } from '../redux/firstAidItemsSlice';
import { InspectorData } from './../pages/Inspector/Inspector.props';
import { formatDate, getTimeStamp } from "./dateTimeHelper";
import { MenuData } from '../redux/sidebarSlice';

export const getInspectorInEvents = (data: any) => {
  return data ? sortInspectorData(data.map((item: any) => {
    return {
      category: item.category,
      companyId: item.companyId,
      createdDate: formatDate(item.createdDate, 'YYYY-MM-DD HH:mm:ss'),
      message: item.message,
      id: item.id,
      detailEvent: item.data,
      startDate: item.createdDate,
      logReferenceId: item.logReferenceId,
    }
  }), 'desc') : [];
};

export const sortInspectorData = (data: InspectorData[], sortDirection: string): InspectorData[] => {
  switch (sortDirection) {
    case 'asc':
      return data.sort((a, b) => +new Date(a.startDate) - +new Date(b.startDate));
    case 'desc':
      return data.sort((a, b) => +new Date(b.startDate) - +new Date(a.startDate));

    default:
      return data.sort((a, b) => +new Date(a.startDate) - +new Date(b.startDate));
  }
};

export const filterInspectorData = (data: InspectorData[], formatDate: Date, toDate: Date) => {
  if (formatDate && toDate && data && data.length > 0) {
    return data.filter(item =>
      getTimeStamp(item.startDate) >= getTimeStamp(formatDate) && getTimeStamp(item.startDate) <= getTimeStamp(toDate));
  }
  return data;
}

export const emergencyNumbersData = (data: any) => {
  if (data) {
    const res: EmergencyNumbersData[] = data.map((item: any) => {
      return {
        description: item.description,
        image: item.image,
        name: item.name,
        phoneNumber: item.phoneNumber
      }
    });
    return res;
  }
  return [];
};

export const firstAidItemsData = (data: any) => {
  if (data) {
    const res: FirstAidItemsRes[] = data.map((item: any) => {
      return {
        id: item.id,
        displayOrder: item.displayOrder,
        displayText: item.displayText,
        components: item.components
      }
    });
    return sortFirstAidItemsData(res, 'asc');
  }
  return [];
};

export const sortFirstAidItemsData = (data: FirstAidItemsRes[], sortDirection: string): FirstAidItemsRes[] => {
  switch (sortDirection) {
    case 'asc':
      return data.sort((a, b) => a.displayOrder - b.displayOrder);
    case 'desc':
      return data.sort((a, b) => b.displayOrder - a.displayOrder);

    default:
      return data.sort((a, b) => a.displayOrder - b.displayOrder);
  }
};

export const menuSubscriberData = [
  {
    id: 'subscriber-1',
    name: 'Account Details',
    type: CONSTANTS.SIDEBAR.TYPE.SUBSCRIBER_DATA_TYPE.ACCOUNT_DETAILS,
  },
  {
    id: 'subscriber-2',
    name: 'Subscription',
    type: CONSTANTS.SIDEBAR.TYPE.SUBSCRIBER_DATA_TYPE.SUBSCRIPTION,
  },
  {
    id: 'subscriber-3',
    name: 'Device Info',
    type: CONSTANTS.SIDEBAR.TYPE.SUBSCRIBER_DATA_TYPE.DEVICE_INFO,
  },
  {
    id: 'subscriber-4',
    name: 'Session Ids',
    type: CONSTANTS.SIDEBAR.TYPE.SUBSCRIBER_DATA_TYPE.SESSION_IDS,
  },
  {
    id: 'subscriber-5',
    name: 'Groups',
    type: CONSTANTS.SIDEBAR.TYPE.SUBSCRIBER_DATA_TYPE.GROUPS,
  },
  {
    id: 'subscriber-6',
    name: 'Invitations Sent',
    type: CONSTANTS.SIDEBAR.TYPE.SUBSCRIBER_DATA_TYPE.INVITATIONS_SENT,
  },
  {
    id: 'subscriber-7',
    name: 'Invitations Received',
    type: CONSTANTS.SIDEBAR.TYPE.SUBSCRIBER_DATA_TYPE.INVITATIONS_RECEIVED,
  },
  {
    id: 'subscriber-8',
    name: 'Contacts',
    type: CONSTANTS.SIDEBAR.TYPE.SUBSCRIBER_DATA_TYPE.CONTACTS,
  },
  {
    id: 'subscriber-9',
    name: 'Check-Ins',
    type: CONSTANTS.SIDEBAR.TYPE.SUBSCRIBER_DATA_TYPE.CHECK_INS,
  },
  {
    id: 'subscriber-10',
    name: 'Watch-Me',
    type: CONSTANTS.SIDEBAR.TYPE.SUBSCRIBER_DATA_TYPE.WATCH_ME,
  },
  {
    id: 'subscriber-11',
    name: 'Additional Details',
    type: CONSTANTS.SIDEBAR.TYPE.SUBSCRIBER_DATA_TYPE.ADDITIONAL_DETAILS,
  },
  {
    id: 'subscriber-12',
    name: 'Interactions',
    type: CONSTANTS.SIDEBAR.TYPE.SUBSCRIBER_DATA_TYPE.INTERACTIONS,
  }
]

export const menuMasterData = [
  {
    id: 'master-1',
    name: 'Emergency Numbers',
    type: CONSTANTS.SIDEBAR.TYPE.MASTER_DATA_TYPE.EMERGENCY_NUMBERS,
  },
  {
    id: 'master-2',
    name: 'Reference Data',
    type: CONSTANTS.SIDEBAR.TYPE.MASTER_DATA_TYPE.REFERENCE_DATA,
  },
  {
    id: 'master-3',
    name: 'Subscriptions',
    type: CONSTANTS.SIDEBAR.TYPE.MASTER_DATA_TYPE.SUBSCRIPTIONS,
  },
  {
    id: 'master-4',
    name: 'First Aid Items',
    type: CONSTANTS.SIDEBAR.TYPE.MASTER_DATA_TYPE.FIRST_AID_ITEMS,
  },
]


export const menuData: MenuData[] = [
  {
    id: 'dashboard-nav',
    name: 'Dashboard',
    type: CONSTANTS.SIDEBAR.TYPE.DASHBOARD,
    childMenu: [],
    icon: 'bi bi-grid',
  },
  {
    id: 'inspector-nav',
    name: 'Inspector',
    type: CONSTANTS.SIDEBAR.TYPE.INSPECTOR,
    childMenu: [],
    icon: 'bi bi-menu-button-wide',
  },
  {
    id: 'subscriber-nav',
    name: 'Subscriber Data',
    type: CONSTANTS.SIDEBAR.TYPE.SUBSCRIBER_DATA,
    childMenu: [],
    icon: 'bi bi-menu-button-wide',
  },
  {
    id: 'master-data-nav',
    name: 'Master Data',
    type: CONSTANTS.SIDEBAR.TYPE.MASTER_DATA,
    childMenu: menuMasterData,
    icon: 'bi bi-journal-text',
  },
  {
    id: 'location-tracking',
    name: 'Location Tracking',
    type: CONSTANTS.SIDEBAR.TYPE.LOCATION_TRACKING,
    childMenu: [],
    icon: 'bi bi-map',
  },
]