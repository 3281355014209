import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getReferenceData } from '../api/emergencyNumbers';
import { RootState } from './store';

export interface ReferenceData {
  title: string;
  content: Array<string | any>;
  id: number;
}
export interface ReferenceObjectState {
  data?: ReferenceData[];
  isLoading: boolean;
  error?: string;
}

const initialState: ReferenceObjectState = {
  isLoading: false,
  data: undefined,
  error: undefined,
}

export const getReference = createAsyncThunk('reference/getReference', async () => {
  const response = await getReferenceData();
  const data = response.lifestreamResponse.data;

  return data ? [
    {
      content: data.bloodType ?? [],
      title: 'Blood Type',
      id: 1,
    },
    {
      content: data.contactRelationShip ?? [],
      title: 'Contact RelationShip',
      id: 2,
    },
    {
      content: data.gender ?? [],
      title: 'Gender',
      id: 3,
    },
    {
      content: data.hairColour ?? [],
      title: 'Hair Color',
      id: 4,
    },
    {
      content: data.height ?? [],
      title: 'Height (cm)',
      id: 5,
    },
    {
      content: data.weight ?? [],
      title: 'Weight (kg)',
      id: 6,
    },
    {
      content: data.livingStatus ?? [],
      title: 'Living Status',
      id: 7,
    },
    {
      content: data.medicalConditions ?? [],
      title: 'Medical Conditions',
      id: 8,
    }
  ] : [];
})

const referenceSlice = createSlice({
  name: 'reference',
  initialState,
  reducers: {
    setReferenceData: (state, action) => {
      state.isLoading = false;
      state.data = action.payload as ReferenceData[];
      state.error = undefined;
    },
    setReferenceClear: (state) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = undefined;
    },

  },
  extraReducers: (builder) => {
    builder.addCase(getReference.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getReference.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload as any;
      state.error = undefined;
    });

    builder.addCase(getReference.rejected, (state, action) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = action.payload as string;
    });
  },
});

export const {
  setReferenceData,
  setReferenceClear,
} = referenceSlice.actions;

export const referenceSelector = (state: RootState) => state.reference;

export default referenceSlice.reducer;