import React from 'react';
import { InputLabelProps } from '.';
import { InputField } from '../InputField/InputField';
import { Text } from '../Text';
import { Colors } from '../../configs/colors';
import './InputLabel.styles.css';

export const InputLabel: React.FC<InputLabelProps> = ({
  onChangeInput,
  type,
  value,
  placeholder = '',
  label,
  disabledInput = false,
  widthInputLabel = 'auto',
  widthInput = 'auto',
  textColor = false,
}) => {
  return (
    <div className="input-label-style flex-fill">
      <div style={{ width: widthInputLabel }}>
        <Text fontWeight="400" fontSize={15} color={Colors.NeutralGrey2}>
          {label}
        </Text>
      </div>
      <InputField
        textColor={textColor}
        width={widthInput}
        disabled={disabledInput}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChangeInput || (() => undefined)}
      />
    </div>
  );
};
