export const durationList = [
    {
      "id": "1",
      "percent_off": 30,
      "duration": "forever",
      "name": "Repeating",
      "applies_to": [
      ]
    },
    {
      "id": "2",
      "percent_off": 30,
      "duration": "forever",
      "name": "Forever",
      "applies_to": [
      ]
    },
    {
      "id": "3",
      "percent_off": 30,
      "duration": "forever",
      "name": "Percentage",
      "applies_to": [
      ]
    },
  ]
  
 export const modeList = [
    {
      "id": "1",
      "percent_off": 30,
      "duration": "forever",
      "name": "Fixed",
      "applies_to": [
      ]
    },
    {
      "id": "2",
      "percent_off": 30,
      "duration": "forever",
      "name": "Percentage",
      "applies_to": [
      ]
    }
  ]
  
  export const currencyList = [
    {
      "id": "1",
      "percent_off": 30,
      "duration": "forever",
      "name": "AUD",
      "applies_to": [
      ]
    },
    {
      "id": "2",
      "percent_off": 30,
      "duration": "forever",
      "name": "USD",
      "applies_to": [
      ]
    },
    {
      "id": "3",
      "percent_off": 30,
      "duration": "forever",
      "name": "NZY",
      "applies_to": [
      ]
    },
  ]

  export const LocationList = [
    {
        lat: -37.8155,
        lng: 144.7127,
        userName: "Krishnakant Mahamuni",
        horizontalAccuracy: 35,
        speedInMetersPerSecond: -1,
        recordedAtUtc: "2023-08-29T21:54:50Z",
        createdDateUt: "2023-08-29T21:54:53.4784324Z",
        appMode: 1,
    },
    {
        lat: -37.8154, 
        lng: 144.7128,
        userName: "Krishnakant Mahamuni",
        horizontalAccuracy: 35,
        speedInMetersPerSecond: -1,
        recordedAtUtc: "2023-08-29T21:54:50Z",
        createdDateUt: "2023-08-29T21:54:53.4784324Z",
        appMode: 0,
    },
    {
        lat: -37.8156, 
        lng: 144.7129,
        userName: "Krishnakant Mahamuni",
        horizontalAccuracy: 35,
        speedInMetersPerSecond: -1,
        recordedAtUtc: "2023-08-29T21:54:50Z",
        createdDateUt: "2023-08-29T21:54:53.4784324Z",
        appMode: 2,
    },
    {
        lat: -37.8153, 
        lng: 144.7126,
        userName: "Krishnakant Mahamuni",
        horizontalAccuracy: 35,
        speedInMetersPerSecond: -1,
        recordedAtUtc: "2023-08-29T21:54:50Z",
        createdDateUt: "2023-08-29T21:54:53.4784324Z",
        appMode: 1,
    },
    {
        lat: -37.8152, 
        lng: 144.7130,
        userName: "Krishnakant Mahamuni",
        horizontalAccuracy: 35,
        speedInMetersPerSecond: -1,
        recordedAtUtc: "2023-08-29T21:54:50Z",
        createdDateUt: "2023-08-29T21:54:53.4784324Z",
        appMode: 0,
    },

];