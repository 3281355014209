import React, { useCallback, useEffect, useState } from 'react'
import { TableHeader } from '../../components/TableHeader/TableHeader'
import { InputFieldVariant } from '../../components/InputField/InputField.props'
import { Colors } from '../../configs/colors'
import { InputField } from '../../components/InputField/InputField'
import './SubscriberData.styles.css'
import { subscriberSearchData } from './SubscriberData.props'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { getSubscriberByNameOrMobileNumber, subscriberSearchSelector } from '../../redux/subscriberSearchSlice'
import { images } from '../../constants/images'
import { ModalConfirm } from '../../components/ModalConfirm/ModalConfirm'
import { deactivateAccount } from '../../redux/deactiveSubscriberSlice'
import { closeModalPopUp, initializePassword, openModalPopUp, setPassword, protectedDataPasswordSelector } from '../../redux/protectedDataPasswordSlice'
import { useNavigate } from 'react-router-dom'
import appConfig from '../../configs/appConfig.json';
import { setBreadcrumb } from '../../redux/sidebarSlice'
import { CONSTANTS } from '../../constants/constants'
import { getSubscriberGroupLength, subscriberGroupDetailsSelector } from '../../redux/subscriberGroupDetailsSlice'

interface SubscriptionDataProps { }


export const SubscriptionData: React.FC<SubscriptionDataProps> = () => {
    const buttonStyles: React.CSSProperties = {
        color: 'red'
    }
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { password, isOpen } = useAppSelector(protectedDataPasswordSelector);
    const subscriberData = useAppSelector(subscriberSearchSelector);
    const subscriberGroupData = useAppSelector(subscriberGroupDetailsSelector);
    const [showTable, setShowTable] = useState<boolean>(false);
    const [data, setData] = useState<subscriberSearchData[]>([])
    const [showDetail, setShowDetail] = useState<boolean>(false);
    const [searchText, setSearchText] = useState('');
    const [userDetails, setUserDetails] = useState<subscriberSearchData>()
    const [showPopupSubscriber, setShowPopupSubscriber] = useState<boolean>(false);
    const [checkFocus, setCheckFocus] = useState<boolean>(false);
    const formattedDate = userDetails ? new Date(userDetails.dateOfBirth).toLocaleDateString() : null;
    const [passwordText, setPasswordText] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [groupLength, setSetGroupLength] = useState<number>(0);

    const dataHeader = [
        {
            type: 'SubscriberId',
            widthType: '20%',

        },
        {
            type: 'Name',
            widthType: '20%',
        },
        {
            type: 'Phone Number',
            widthType: '20%',
        },
        {
            type: 'registration Status',
            widthType: '20%',
        },
        {
            type: 'Is-Active',
            widthType: '20%',
        },

    ];

    const handleClickRow = (value: any) => {
        setUserDetails(value?.account)
        const trElements = document.querySelectorAll('[id^="tr-"]');
        trElements.forEach((element) => {
            element.classList.remove('tr-active');
        });

        const trElement = document.getElementById(`tr-${value?.account.subscriberId}`);

        if (trElement) {
            trElement.classList.add('tr-active');
            dispatch(getSubscriberGroupLength(value?.account.subscriberId))
            setShowDetail(true);
        } else {
            setShowDetail(false);
        }
    };

    const onKeyPress = (event: any) => {
        if (event.charCode === 10 || event.charCode === 13) {
            event.preventDefault();
            onSearchPress(searchText);
        }
    };

    const onSearchPress = (val̥ue: string) => {
        if (val̥ue) {
            dispatch(getSubscriberByNameOrMobileNumber(val̥ue)).then(response => { 
                setData(response.payload.data)
                setShowTable(true)
                setShowDetail(false)

            }) 
        }
    }

    const handleShowPopUpSubscriber = (data: any) => {
        setShowPopupSubscriber(true)
    };

    const onConfirm = (subscriberId: string) => {
        dispatch(deactivateAccount(subscriberId)).then(() => {
            setShowPopupSubscriber(false);
            onSearchPress(searchText);
        });

    };

    const onClickOk = () => {
        if (passwordText !== '') {
            setErrorMessage('')
            if (atob(appConfig?.Protected_Data_Password?.password) === passwordText) {
                dispatch(closeModalPopUp());
                navigate('/subscriber-data');
                dispatch(setPassword(passwordText));
                dispatch(setBreadcrumb(CONSTANTS.SIDEBAR.BREADCRUMB.SUBSCRIBERS_DATA));
            } else {
                navigate('/error');
                dispatch(closeModalPopUp());
                dispatch(setBreadcrumb(CONSTANTS.SIDEBAR.BREADCRUMB.ERROR));
            }
        } else {
            setErrorMessage('Password required');
        }
    };

    useEffect(() => {
        if (searchText === '') {
            setShowTable(false)
            setShowDetail(false)
        }
    }, [searchText])

    useEffect(() => {
        if (!password) {
            dispatch(openModalPopUp());
        }
        else {
            if (atob(appConfig?.Protected_Data_Password?.password) === password) {
                dispatch(closeModalPopUp());
                navigate('/subscriber-data');
                dispatch(setBreadcrumb(CONSTANTS.SIDEBAR.BREADCRUMB.SUBSCRIBERS_DATA));
            }
            else {
                setErrorMessage('Password required');
            }
        }
    }, [password])

    useEffect(() => {
        dispatch(initializePassword());
    }, [])
    useEffect(() => {
        setSetGroupLength(subscriberGroupData.data.numberOfGroups)
    }, [subscriberGroupData])

    return (
        <>
            <div className="col-lg-10 col-sm-10">
                <div className={`search-bar-text-wrap ${checkFocus ? 'focused' : ''}`}>
                    <InputField
                        variant={InputFieldVariant.Labelled}
                        width="100%"
                        label="Enter person's name or mobile no"
                        labelColor={Colors.NeutralGrey2}
                        type="text"
                        alt=""
                        placeholder="Enter person's name or mobile no"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        onCheckFocus={(value) => setCheckFocus(value)}
                        closeIcon={!!searchText}
                        onClearValue={() => setSearchText('')}
                        height="50px"
                        onKeyPress={onKeyPress}
                    />
                    <button
                        className="button-style"
                        onClick={() => onSearchPress(searchText)}
                    >
                        Search
                    </button>
                </div>

                {

                    !subscriberData.isLoading ? (
                        showTable && (
                            <div className="table">
                                <TableHeader dataHeader={dataHeader} />
                                <div className="table-content scroll-custom">

                                    {
                                        data && data?.map((item: any) => (
                                            <div key={item.account.subscriberId}>
                                                <div >
                                                    <div className="table-row-content" id={`tr-${item.account.subscriberId}`} onClick={() => handleClickRow(item)}>
                                                        <div style={{ width: dataHeader[0].widthType }} className='hide-large-content'>{item.account.subscriberId}</div>
                                                        <div style={{ width: dataHeader[1].widthType }}>{item.account.firstName} {item.account.lastName}</div>
                                                        <div style={{ width: dataHeader[2].widthType }}>{item.account.mobileNumber.countryCode} {item.account.mobileNumber.number}</div>
                                                        <div style={{ width: dataHeader[3].widthType }} className='hide-large-content'>{item.account.registrationStatus}</div>
                                                        <div style={{ width: dataHeader[4].widthType }}>
                                                            <div className='d-flex'>
                                                                {item.account.isDeleted == false ? <span className="is-active">●</span> : <span className="is-deleted">●</span>}

                                                                <i className="bi bi-chevron-right ms-auto"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        ))
                                    }
                                </div>
                            </div>
                        )
                    ) : (
                        <div className="d-flex justify-content-center">
                            <div className="loader-image">
                                <img src={images.Spinner} alt="" />
                            </div>
                        </div>
                    )
                }

            </div>
            {showDetail && (
                <div className="col-lg-12 col-sm-12">
                    <div className="card">
                        <div className="card-header p-0 d-flex align-items-center">
                            <div className="col-lg-12">
                                <div className="row ">
                                    <div className="col-lg-10">
                                        <h6 >Subscriber details</h6>
                                    </div>
                                    <div className="col-lg-2">
                                        {userDetails?.isDeleted == true ? '' : <button className='btn btn-danger' onClick={(event) => handleShowPopUpSubscriber(event)}>Deactive</button>}

                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className="card-body">
                            <div className="col-lg-12">
                                <div className="row d-flex">
                                    <div className="col-lg-3 col-sm-3">
                                        <div className='card'>
                                            <ul className="sidebar-nav" id="sidebar-nav">
                                                <li className="nav-item">

                                                    <div className={`nav-link collapsed`}>

                                                        <span>Account Details</span>

                                                    </div>
                                                    <div className={`nav-link collapsed`}>

                                                        <span>Membership</span>

                                                    </div>
                                                    <div className={`nav-link collapsed`}>

                                                        <span>Device info</span>

                                                    </div>
                                                    <div className={`nav-link collapsed`}>

                                                        <span>Recent</span>

                                                    </div>
                                                    <div className={`nav-link collapsed`}>

                                                        <span>Groups</span>

                                                    </div>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                    <div className="col-lg-9 col-sm-9">
                                        <div className="card">
                                            <div className="card-header">
                                                <h6>Account Details</h6>
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-sm-6 d-flex">
                                                        <label htmlFor="">Name:</label>
                                                        <span className='ml-4'>{userDetails?.firstName} {userDetails?.lastName}</span>
                                                    </div>
                                                    <div className="col-sm-6 d-flex">
                                                        <label htmlFor="">Mobile:</label>
                                                        <span className='ml-4'>{userDetails?.mobileNumber.countryCode} {userDetails?.mobileNumber.number}</span>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-sm-6 d-flex">
                                                        <label htmlFor="">Email:</label>
                                                        <span className='ml-4'>{userDetails?.emailAddress}</span>

                                                    </div>
                                                    <div className="col-sm-6 d-flex">
                                                        <label htmlFor="">DOB:</label>
                                                        <span className='ml-4'>{formattedDate}</span>

                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6 d-flex">
                                                        <label htmlFor="">Status:</label>
                                                        <span className='ml-4'>{userDetails?.registrationStatus}</span>

                                                    </div>
                                                    <div className="col-sm-6 d-flex">
                                                        <label htmlFor="">Is Deleted:</label>
                                                        {userDetails?.isDeleted === true ? <span className='ml-4'>True</span> : <span className='ml-4'>False</span>}

                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                           

                        </div>
                    </div>
                    <div className='group-container'>
                        <div className="row">
                            <div className="col-lg-12 col-sm-12  d-flex">
                                <div className='icon-layout'>
                                    <i className="bi bi-people  group-icon"></i>
                                </div>
                                <div className='group-text'>
                                <b>{groupLength}</b>
                                <span>
                                    (Excluding reserved groups)
                                </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {
                showPopupSubscriber && (
                    <ModalConfirm
                        onConfirm={() => onConfirm(userDetails!.subscriberId)}
                        title="Deactivate account"
                        content="Are you sure you wish to deactivate this account? Please note that this action is irreversible"
                        onClose={() => setShowPopupSubscriber(false)} confirmText="Confirm" buttonStyles={buttonStyles} />
                )
            }

            {
                isOpen && <ModalConfirm
                    onConfirm={() => onClickOk()}
                    title="Password"
                    content="This page is access-restricted by password. If you do not possess the password, kindly contact the Tech team to request access"
                    onClose={() => {
                        navigate('/error');
                        dispatch(closeModalPopUp());
                        dispatch(setBreadcrumb(CONSTANTS.SIDEBAR.BREADCRUMB.ERROR));

                    }} confirmText="OK" >
                    <div className='mt-2'>
                        <label htmlFor="password">Password</label>
                        <input className='form-control' type='password' onChange={(e) => setPasswordText(e.target.value)} placeholder='Enter Your Password' />
                        {errorMessage !== '' ? (<p style={{ color: 'red' }}>Required</p>) : ('')}
                    </div>
                </ModalConfirm>
            }


        </>

    )
}