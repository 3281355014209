import React, { ReactNode } from 'react';
import { images } from '../../constants/images';
import './ModalConfirm.styles.css';
import { Text } from '../Text';
import { Colors } from '../../configs/colors';

interface ModalConfirmProps {
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  content: string;
  cancelText?: string;
  confirmText?: string;
  children?: ReactNode;
  buttonStyles?: React.CSSProperties,
}

export const ModalConfirm: React.FC<ModalConfirmProps> = ({
  onClose,
  onConfirm,
  title,
  content,
  cancelText = 'Cancel',
  confirmText = 'Proceed',
  children,
  buttonStyles ={}
}) => {
  return (
    <div className="modal-confirm">
      <div className="modal-confirm-body">
        <div className="modal-confirm-header">
          <Text fontSize={18} fontWeight="700" color={Colors.LSNavy}>
            {title}
          </Text>
          <div className="close">
            <img width="24px" src={images.Close} onClick={onClose} alt="" />
          </div>
        </div>
        <div className="modal-confirm-content">
          <Text fontSize={14} fontWeight="600" color={Colors.LSNavy}>
            {content}
          </Text>
          {children && (
            <div className="modal-children">
              {children}
            </div>
          )}
        </div>
        <div className="modal-confirm-footer">
          <button className="cancel" onClick={onClose}>
            {cancelText}
          </button>
          <button className="process" style={buttonStyles} onClick={onConfirm}>
            {confirmText}
          </button>
        </div>
      </div>
    </div>
  );
};
