import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAllPromoCodes, getMembershipCountByPromocodeId, getPromoCodesDetail } from '../api/subscriptions';
import { PromoCodesData, PromoCodesDetailData } from "../pages/Dashboard/Dashboard.props";
import { RootState } from './store';

export interface PromoCodeListObjectState {
  data?: PromoCodesData[];
  isLoading: boolean;
  error?: string;
}

const initialState: PromoCodeListObjectState = {
  isLoading: false,
  data: undefined,
  error: undefined,
}

export const getMembershipCountForPromocode = createAsyncThunk('v1/subscriptions/promocodes/membershipCount', async (promocodeId: string) => {
  const response = await getMembershipCountByPromocodeId(promocodeId);
  return response.lifestreamResponse.data;
});

export const getPromoCodeList = createAsyncThunk('v1/subscriptions/promocodes', async () => {
  const response = await getAllPromoCodes();
  const dataResponse = response.lifestreamResponse.data;
  return dataResponse ? dataResponse.map((promocode: any) => {
    return {
      stripeCouponId: promocode.stripeCouponId,
      promoCodeId: promocode.promoCodeId,
      code: promocode.code,
      discount: promocode.discount,
      name: promocode.name,
      mode: promocode.mode,
      duration: promocode.duration
    };
  }) : [];
})

const promoCodeListSlice = createSlice({
  name: 'promoCodeList',
  initialState,
  reducers: {
    setPromoCodeListData: (state, action: PayloadAction<PromoCodesData[]>) => {
      state.isLoading = false;
      state.data = action.payload;
      state.error = undefined;
    },
    setPromoCodeListClear: (state) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = undefined;
    },

  },
  extraReducers: (builder) => {
    builder.addCase(getPromoCodeList.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getPromoCodeList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload as any;
      state.error = undefined;
    });

    builder.addCase(getPromoCodeList.rejected, (state, action) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = action.payload as string;
    });
  },
});

export const {
  setPromoCodeListData,
  setPromoCodeListClear,
} = promoCodeListSlice.actions;

export const promoCodeListSelector = (state: RootState) => state.promoCodeList;

export default promoCodeListSlice.reducer;
