import React from 'react';
import { useAppSelector } from '../redux/hooks';
import { sidebarSelector } from '../redux/sidebarSlice';

interface BreadcrumbProps {}

export const Breadcrumb: React.FC<BreadcrumbProps> = () => {
  const { breadcrumb } = useAppSelector(sidebarSelector);

  const items = breadcrumb.split('/');

  return (
    <div>
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <div>Home</div>
            </li>
            {items.map((item, index) => (
              <li
                key={item}
                className={`breadcrumb-item ${items.length - 1 === index ? 'active' : ''}`}
              >
                {item}
              </li>
            ))}
          </ol>
        </nav>
      </div>
    </div>
  );
};
