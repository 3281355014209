import moment from 'moment';

export const getDateTimeDifference = (givenDateTime: Date): number => {
  return givenDateTime? moment().diff(givenDateTime, 'years'): 0;
};

export const formatDate = (strDateTime: string, format: string): string => {
  return moment(strDateTime).format(format);
};

export const getDateTime = (dateObj?: Date): string => {
  if (dateObj) {
    return moment(dateObj).format('LT');
  }
  return 'n/a';
};

export const getCurrentIsoDate = (): any => {
  return {
    from: moment().startOf('day').toISOString(),
    to: moment().endOf('day').toISOString(),
  };
};

export const getTimeStamp = (date: Date): number => {
  return moment(date).unix();
};


export const getCurrentDate = (): Date => {
  const today = new Date(moment().toDate());
  return today;
};
