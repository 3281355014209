import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import appConfig from '../../configs/appConfig.json';
import { images } from '../../constants/images';
import React from 'react';
import { MapProps } from './Map.props';

const Map: React.FC<MapProps> = ({ mapData, center, zoom, containerStyle }) => {
    const WIDTH = 30, HEIGHT = 30;
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: appConfig.Google.apiKey
    });
    
    return (
        <div data-testid='map-container'>
            {!isLoaded ? (<p>Loading...</p>) : (
                <GoogleMap center={center} zoom={zoom} mapContainerStyle={containerStyle}
                    options={
                        {
                            mapTypeControlOptions: { position: window.google.maps.ControlPosition.TOP_RIGHT, }, fullscreenControlOptions: {
                                position: window.google.maps.ControlPosition.BOTTOM_RIGHT, // Change the position to top-right
                            },
                        }
                    }>
                    {mapData?.map((location, index) => (
                        <MarkerF
                            key={index}
                            position={{ lat: location.lat, lng: location.lng }}
                            
                            icon={{
                                url: getMarkerIcon(location.appMode),
                                scaledSize: new window.google.maps.Size(WIDTH, HEIGHT),
                            }}
                        />
                    ))}
                </GoogleMap>
            )}

        </div>
    );
}

export const getMarkerIcon = (appMode: number): string => {
    switch (appMode) {
        case 0:
            return images.GreenMarker;
        case 1:
            return images.BlackMarker;
        case 2:
            return images.RedMarker;
        default:
            return images.BlackMarker;
    };
}


export default Map;