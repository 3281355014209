/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { DatePicker } from '../../components/DatePicker/DatePicker';
import { InputField } from '../../components/InputField/InputField';
import { InputFieldVariant } from '../../components/InputField/InputField.props';
import { TableHeader } from '../../components/TableHeader/TableHeader';
import { TimeFilter } from '../../components/TimeFilter/TimeFilter';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  inspectorEventsDataSelector,
  inspectorEvents,
  setClearInspectorEventData,
  subscriberDetailsByPhoneNumber,
} from '../../redux/inspectorSlice';
import { InspectorData } from './Inspector.props';
import { images } from '../../constants/images';
import './Inspector.style.css';
import { InspectorPopup } from '../../components/InspectorPopUp/InspectorPopup';
import { getCurrentDate } from '../../utils/dateTimeHelper';
import { ToastMessage } from '../../components/ToastMessage/ToastMessage';
import {
  setSubscriberDetailsClear,
  subscriberDetailsById,
  subscriberDetailsSelector,
} from '../../redux/subscriberDetailsSlice';
import { SubscriberPopup } from '../../components/SubscriberPopUp/SubscriberPopup';

interface InspectorProps {}
export const Inspector: React.FC<InspectorProps> = () => {
  const dispatch = useAppDispatch();
  const inspectorEventsData = useAppSelector(inspectorEventsDataSelector);
  const subscriberDetailData = useAppSelector(subscriberDetailsSelector);
  const [inputValue, setInputValue] = useState<string>('');
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
  const [inspectorData, setInspectorData] = useState<InspectorData>();
  const [showPopupInspector, setShowPopupInspector] = useState<boolean>(false);
  const [isClear, setIsCLear] = useState<boolean>(false);
  const [showPopupSubscriber, setShowPopupSubscriber] = useState<boolean>(false);

  const dataHeader = [
    {
      type: 'Message',
      widthType: '30%',
    },
    {
      type: 'Created Date',
      widthType: '18%',
    },
    {
      type: 'Correlation ID',
      widthType: '30%',
    },
    {
      type: 'Category',
      widthType: '15%',
    },
    {
      type: '',
      widthType: '7%',
    },
  ];

  const onKeyPress = (evt: any) => {
    if ((evt.charCode === 10 || evt.charCode === 13) && !evt.shiftKey) {
      evt.preventDefault();
      setShowDatePicker(false);
      setIsCLear(!isClear);
      const trimInputValue = inputValue.trim();
      if (trimInputValue.slice(0, 3) === 'crl') {
        dispatch(inspectorEvents({ correlationId: trimInputValue }));
        dispatch(setSubscriberDetailsClear());
      } else if (!(/\D/).test(trimInputValue)) {
        dispatch(subscriberDetailsByPhoneNumber(trimInputValue)).then((data) => {
          const res: any = data.payload;
          if (res) {
            dispatch(inspectorEvents({ subscriberId: res.subscriberId }));
            dispatch(subscriberDetailsById(res.subscriberId));
          } else {
            dispatch(setSubscriberDetailsClear());
            dispatch(inspectorEvents({ subscriberId: trimInputValue }));
          }
        });
      } else {
        dispatch(inspectorEvents({ subscriberId: trimInputValue }));
        dispatch(subscriberDetailsById(trimInputValue));
      }
    }
  };

  const triggerDatePicker = () => {
    setShowDatePicker(!showDatePicker);
    setTimeout(() => {
      const datePicker = document.getElementById('date-range-input');
      if (datePicker) {
        datePicker.click();
      }
    }, 200);
  };

  const handleClickRow = (value: InspectorData) => {
    // show and hide child row
    if (value.events.length > 0) {
      value.events.map((event) => {
        const elm = document.getElementById(event.id);
        if (elm?.className.includes('show')) {
          elm?.classList.remove('show');
        } else {
          elm?.classList.add('show');
        }
        return null;
      });
    }
    // show caret-down or caret-up
    const elm = document.getElementById(value.id);
    if (elm?.className.includes('bi-caret-down-fill')) {
      elm?.classList.remove('bi-caret-down-fill');
      elm?.classList.add('bi-caret-up-fill');
    } else {
      elm?.classList.add('bi-caret-down-fill');
      elm?.classList.remove('bi-caret-up-fill');
    }
    // show active or inactive
    const trElement = document.getElementById(`tr-${value.id}`);
    if (trElement?.className.includes('tr-active')) {
      trElement?.classList.remove('tr-active');
    } else {
      trElement?.classList.add('tr-active');
    }
  };

  const handleShowPopUpInspector = (data: InspectorData) => {
    setInspectorData(data);
    setShowPopupInspector(true);
  };

  const onToggleValue = (mins: number) => {
    const fromDate = getCurrentDate();
    const sum = Date.parse(fromDate.toString()) + mins * 60000;
    const toDate = new Date(sum);
    const from = fromDate.toISOString();
    const to = toDate.toISOString();
    dispatch(inspectorEvents({ dateRange: { from, to } }));
    setInputValue('');
    dispatch(setSubscriberDetailsClear());
  };

  const onApply = (fromDate: Date, toDate: Date) => {
    const from = fromDate.toISOString();
    const to = toDate.toISOString();
    dispatch(inspectorEvents({ dateRange: { from, to } }));
    setInputValue('');
    dispatch(setSubscriberDetailsClear());
  };

  const onClearValue = () => {
    dispatch(setClearInspectorEventData());
  };

  return (
    <div className="inspector">
      <div className="row mb-3 first-row">
        <div className="col-sm-8 custom-left-filter">
          <InputField
            variant={InputFieldVariant.SearchBar}
            type="text"
            placeholder="search"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyPress={onKeyPress}
          />
        </div>
        <div className="col-sm-4 custom-right-filter">
          <div className="daterange-filter">
            {!showDatePicker && (
              <TimeFilter
                onToggleValue={(value) => onToggleValue(value)}
                onClearValue={onClearValue}
                isClear={isClear}
              />
            )}
            {!showDatePicker && (
              <button onClick={() => triggerDatePicker()} className="custom-filter">
                Custom<i className="bi bi-calendar"></i>
              </button>
            )}
            {showDatePicker && (
              <DatePicker
                setShowDatePicker={setShowDatePicker}
                onApplyFromDateToDate={onApply}
                onClearValue={onClearValue}
              />
            )}
          </div>
        </div>
      </div>

      <div className="card card-table">
        <div className="card-body">
          <div className="card-title-group">
            <div className="card-title">Inspectors Data</div>
            <button
              onClick={() => setShowPopupSubscriber(!showPopupSubscriber)}
              className="subscriber-detail"
              style={{ width: dataHeader[4].widthType }}
              disabled={subscriberDetailData.data ? false : true}
            >
              Subscriber Detail
            </button>
          </div>
          <div className="table">
            <TableHeader dataHeader={dataHeader} />
            <div className="table-content scroll-custom">
              {(!inspectorEventsData.data || inspectorEventsData.data.length === 0) &&
                !inspectorEventsData.isLoading && <div className="no-data">No Data Available</div>}
              {!inspectorEventsData.isLoading ? (
                inspectorEventsData.data &&
                inspectorEventsData.data.map((item: InspectorData) => (
                  <div key={item.id}>
                    <div className="table-row-content" id={`tr-${item.id}`}>
                      <div style={{ width: dataHeader[0].widthType }}>
                        {item.events.length > 0 && (
                          <i
                            id={item.id}
                            onClick={() => handleClickRow(item)}
                            className="bi bi-caret-down-fill"
                          ></i>
                        )}{' '}
                        {item.message}
                      </div>
                      <div style={{ width: dataHeader[1].widthType }}>{item.createdDate}</div>
                      <div style={{ width: dataHeader[2].widthType }}>{item.correlationId}</div>
                      <div style={{ width: dataHeader[3].widthType }}>{item.category}</div>
                      {Object.keys(item.detailEvent).length > 0 && (
                        <div
                          onClick={() => handleShowPopUpInspector(item)}
                          className="detail-options"
                          style={{ width: dataHeader[4].widthType }}
                        >
                          View Detail
                        </div>
                      )}
                    </div>
                    {item.events.length > 0
                      ? item.events.map((event) => (
                          <div key={event.id}>
                            <div
                              id={event.id}
                              className="table-row-content child-content collapse"
                              onDoubleClick={() => handleShowPopUpInspector(event)}
                            >
                              <div style={{ width: dataHeader[0].widthType }}>{event.message}</div>
                              <div style={{ width: dataHeader[1].widthType }}>
                                {event.createdDate}
                              </div>
                              <div style={{ width: dataHeader[2].widthType }}>
                                {event.correlationId}
                              </div>
                              <div style={{ width: dataHeader[3].widthType }}>{event.category}</div>
                              {Object.keys(event.detailEvent).length > 0 && (
                                <div
                                  onClick={() => handleShowPopUpInspector(event)}
                                  className="detail-options"
                                  style={{ width: dataHeader[4].widthType }}
                                >
                                  View Detail
                                </div>
                              )}
                            </div>
                            {showPopupInspector && inspectorData?.id === event.id && (
                              <InspectorPopup
                                detailEvent={inspectorData.detailEvent}
                                logReferenceId={inspectorData.logReferenceId}
                                onClose={(value) => setShowPopupInspector(value)}
                              />
                            )}
                          </div>
                        ))
                      : null}
                    {showPopupInspector && inspectorData?.id === item.id && (
                      <InspectorPopup
                        detailEvent={inspectorData.detailEvent}
                        onClose={(value) => setShowPopupInspector(value)}
                      />
                    )}
                  </div>
                ))
              ) : (
                <div className="d-flex justify-content-center">
                  <div className="loader-image">
                    <img src={images.Spinner} alt="" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {inspectorEventsData.status && (
        <ToastMessage
          type={inspectorEventsData.status}
          message={inspectorEventsData.message || ''}
        />
      )}
      {showPopupSubscriber && (
        <SubscriberPopup
          subscriberDetail={subscriberDetailData.data}
          onClose={() => setShowPopupSubscriber(false)}
        />
      )}
    </div>
  );
};
