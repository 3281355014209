import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getMembershipListByPromocodeId } from '../api/subscriptions';
import { MembershipData } from "../pages/Dashboard/Dashboard.props";
import { RootState } from './store';
import { formatDate } from '../utils/dateTimeHelper';

export interface MembershipListApiParam {
  promocodeId: string;
  pageKey?: string;
  fromDate?:string
  toDate?:string
}

export interface MembershipListObject {
  data: MembershipData[];
  nextPage: boolean;
  pagekey?: string;
}

export interface MembershipListObjectState {
  data?: MembershipListObject;
  isLoading: boolean;
  error?: string;
}

const initialState: MembershipListObjectState = {
  isLoading: false,
  data: undefined,
  error: undefined,
}

export const membershipListForPromocode = createAsyncThunk('v1/subscriptions/promocode/memberships', async (param: MembershipListApiParam) => {
  const response = await getMembershipListByPromocodeId(param.promocodeId, param.pageKey,param.fromDate, param.toDate);
  const dataResponse = response.lifestreamResponse.data;
  const membershipList =  (dataResponse && dataResponse.data) ? dataResponse.data.map((membership: any) => {
    return {
      companyId: membership.companyId,
      subscriberId: membership.subscriberId,
      firstName: membership.firstName,
      lastName: membership.lastName,
      email: membership.email,
      createdDate: membership.createdDate ? formatDate(membership.createdDate, 'DD MMM YYYY HH:mm:ss') : '',
      membership: 'Premium',
      promoCodeId: membership.promoCodeId,
      status: membership.status,
      paymentMethod: membership.paymentMethod,
      freeTrialStatus: membership.freeTrialStatus
    };
  }) : [];

  return {
    data: membershipList,
    nextPage: dataResponse.nextPage,
    pagekey: dataResponse.pagingkey
  }
})

const membershipListForPromocodeSlice = createSlice({
  name: 'membershipListForPromocode',
  initialState,
  reducers: {
    setMembershipListForPromocodeData: (state, action: PayloadAction<MembershipListObject>) => {
      state.isLoading = false;
      state.data = action.payload;
      state.error = undefined;
    },
    setMembershipListForPromocodeClear: (state) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = undefined;
    },

  },
  extraReducers: (builder) => {
    builder.addCase(membershipListForPromocode.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(membershipListForPromocode.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload as any;
      state.error = undefined;
    });

    builder.addCase(membershipListForPromocode.rejected, (state, action) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = action.payload as string;
    });
  },
});

export const {
  setMembershipListForPromocodeData,
  setMembershipListForPromocodeClear,
} = membershipListForPromocodeSlice.actions;

export const membershipListForPromocodeSelector = (state: RootState) => state.membershipListForPromocode;

export default membershipListForPromocodeSlice.reducer;
