import { CONSTANTS } from '../constants/constants';
import { HttpMethod, httpClient } from './httpClient';


export const getSubscriptionsBySubscriberId = (SubscriberId : string ): Promise<any> => {
  return httpClient(`/subscriptions/subscriptions/${SubscriberId }?includeFeatures=true`, HttpMethod.Get, null, CONSTANTS.SERVICE_TYPES.SUBSCRIPTIONS);
};

export const getListOfPromoCodes = (): Promise<any> => {
  return httpClient('/subscriptions/subscriptions/promo-codes', HttpMethod.Get, null, CONSTANTS.SERVICE_TYPES.SUBSCRIPTIONS);
};

export const getPromoCodesDetail = (code: string): Promise<any> => {
  return httpClient('/subscriptions/subscriptions/'+code+'/enrollments/active', HttpMethod.Get, null, CONSTANTS.SERVICE_TYPES.SUBSCRIPTIONS);
};

export const getCouponList = (): Promise<any> => {
  return httpClient('/subscriptions/v1/subscriptions/coupons', HttpMethod.Get, null, CONSTANTS.SERVICE_TYPES.SUBSCRIPTIONS);
};

export const getSubscriptions = (): Promise<any> => {
  return httpClient(`/subscriptions/v1/subscriptions?includeFeatures=true`, HttpMethod.Get, null, CONSTANTS.SERVICE_TYPES.SUBSCRIPTIONS);
};

export const seedPromoCode = (data:{}): Promise<any> => {
  return httpClient(`/subscriptions/v1/subscriptions/seed-promo-code`, HttpMethod.Post, data, CONSTANTS.SERVICE_TYPES.SUBSCRIPTIONS);
};

export const getAllPromoCodes = (): Promise<any> => {
  return httpClient('/subscriptions/v1/subscriptions/promocodes', HttpMethod.Get, null, CONSTANTS.SERVICE_TYPES.SUBSCRIPTIONS);
};

export const getMembershipCountByPromocodeId = (promocodeId: string): Promise<any> => {
  return httpClient(`/subscriptions/v1/subscriptions/promocode/${promocodeId}/number-of-memberships`, HttpMethod.Get, null, CONSTANTS.SERVICE_TYPES.SUBSCRIPTIONS);
};

export const getMembershipListByPromocodeId = (promocodeId: string, pageKey?: string, fromDate?:string, toDate?:string): Promise<any> => {
  let urlPath = `/subscriptions/v1/subscriptions/promocode/${promocodeId}/memberships`
  const queryParams: string[] = [];
  if(pageKey){
    queryParams.push(`pageKey=${pageKey}`);
  }
  if(fromDate && toDate){
    queryParams.push(`fromDateTimeInISO=${fromDate}&toDateTimeInISO=${toDate}`);
  }
  if (queryParams.length > 0) {
    urlPath += `?${queryParams.join('&')}`;
  }
  

  return httpClient(urlPath, HttpMethod.Get, null, CONSTANTS.SERVICE_TYPES.SUBSCRIPTIONS);
};