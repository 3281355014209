export const CONSTANTS = {
  PROFILE: {
    TYPE: {
      OVERVIEW: 'OVERVIEW',
      EDIT: 'EDIT',
      SETTING: 'SETTING',
      CHANGE_PASSWORD: 'CHANGE_PASSWORD'
    },
    TITLE: {
      OVERVIEW: 'Overview',
      EDIT: 'Edit Profile',
      SETTING: 'Settings',
      CHANGE_PASSWORD: 'Change Password'
    }
  },
  SIDEBAR: {
    BREADCRUMB: {
      DASHBOARD: 'Dashboard',
      INSPECTOR: 'Inspector',
      MASTER_DATA: 'Master Data',
      USER_PROFILE: 'User/Profile',
      EMERGENCY_NUMBERS: 'Master Data/Emergency Numbers',
      SUBSCRIPTIONS: 'Master Data/Subscriptions',
      REFERENCE_DATA: 'Master Data/Reference Data',
      FIRST_AID_ITEMS: 'Master Data/First Aid Items',
      SUBSCRIBERS_DATA:'Subscriber Data',
      SUBSCRIBER_DATA: {
        SUBSCRIPTION: 'Subscriber Data/Subscription',
      },
      LOCATION_TRACKING: 'Location Tracking',
      ERROR: 'Error'
    },
    TYPE: {
      DASHBOARD: 'DASHBOARD',
      INSPECTOR: 'INSPECTOR',
      MASTER_DATA: 'MASTER_DATA',
      MASTER_DATA_TYPE: {
        EMERGENCY_NUMBERS: 'EMERGENCY_NUMBERS',
        REFERENCE_DATA: 'REFERENCE_DATA',
        SUBSCRIPTIONS: 'SUBSCRIPTIONS',
        FIRST_AID_ITEMS: 'FIRST_AID_ITEMS',
      },
      USER_PROFILE: 'USER_PROFILE',
      SUBSCRIBER_DATA: 'SUBSCRIBER_DATA',
      SUBSCRIBER_DATA_TYPE: {
        ACCOUNT_DETAILS: 'ACCOUNT_DETAILS',
        SUBSCRIPTION: 'SUBSCRIPTION',
        DEVICE_INFO: 'DEVICE_INFO',
        SESSION_IDS: 'SESSION_IDS',
        GROUPS: 'GROUPS',
        INVITATIONS_SENT: 'INVITATIONS_SENT',
        INVITATIONS_RECEIVED: 'INVITATIONS_RECEIVED',
        CONTACTS: 'CONTACTS',
        CHECK_INS: 'CHECK_INS',
        WATCH_ME: 'WATCH_ME',
        ADDITIONAL_DETAILS: 'ADDITIONAL_DETAILS',
        INTERACTIONS: 'INTERACTIONS',
      },
      LOCATION_TRACKING: 'LOCATION_TRACKING',
    },
    TITLE: {
      DASHBOARD: 'Dashboard',
      INSPECTOR: 'Inspector',
      MASTER_DATA: 'Master Data',
      SUBSCRIBER_DATA: 'Subscriber Data',
    }
  },
  SERVICE_TYPES: {
    MSGRAPH: 'msGraph',
    PLATFORM: "platform",
    SUBSCRIBERS: 'subscribers',
    EMERGENCY_NUMBERS: 'emergencyNumbers',
    REFERENCE_DATA: 'referenceData',
    SUBSCRIPTIONS: 'subscriptions',
  },
  'CALLER_OVERVIEW': {
    'LABEL': {
      SEX: 'Sex:',
      WEIGHT: 'Weight (kg):',
      HEIGHT: 'Height (cm):',
      HAIR_COLOR: 'Hair color:',
      LIVING_STATUS: 'Living status:',
      ETHNICITY: 'Ethnicity:',
      NAME: 'Name:'
    },
    'TYPE_CHANGE': {
      SEX: 'sex',
      WEIGHT: 'weight',
      HEIGHT: 'height',
      HAIR_COLOR: 'hairColour::',
      LIVING_STATUS: 'livingStatus:',
      ETHNICITY: 'ethnicity:'
    },
  },
  'CALLER_DETAILS': {
    'LABEL': {
      MOBILE: 'Mobile:',
      HOME: 'Home:',
      WORK: 'Work:',
      EMAIL: 'Email:',
      ADDRESS: 'Address:',
    },
    'TYPE_CHANGE': {
      MOBILE: 'mobile',
      HOME: 'home',
      WORK: 'work',
      EMAIL: 'email',
      ADDRESS: 'address',
    },
  },
}