import React, { ReactNode, useState } from 'react';
import { Colors } from '../../configs/colors';
import { Text } from '../Text';
import './Accordion.styles.css';

interface AccordionProps {
  title: string;
  children?: ReactNode;
}

export const SubscriptionsAccodion: React.FC<AccordionProps> = ({ title = '', children }) => {
  const [show, setShow] = useState(false);
  const toggleItems = () => {
    setShow(!show);
  };

  return (
    <div className="accordion-container">
      <div className="card my-2">
        <div className="row justify-content-between pr-5 pl-5 p-3" onClick={toggleItems}>
          <Text color={Colors.LSNavy} fontSize={16} fontWeight="700">
            {title}
          </Text>
          <div className="chevron-container">
            <i className={`bi bi-chevron-${show ? 'up' : 'down'}`}></i>
          </div>
        </div>
        <div className="accordion-content m-0 pr-4 pl-4">{show && children}</div>
      </div>
    </div>
  );
};
