export interface InputFieldProps {
  alt?: string;
  type: string;
  placeholder: string;
  value: string;
  width?: string;
  height?: string;
  border?: string;
  boxShadow?: string;
  onChange: (event: any) => any;
  variant?: InputFieldVariant;
  label?: string,
  labelColor?: string,
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  disabled?: boolean;
  textColor?: boolean;
  onKeyPress?: (event: any) => any;
  onCheckFocus?: (value: boolean) => void;
  closeIcon?: boolean;
  onClearValue?: () => void;
}

export enum InputFieldVariant {
  General = 'general',
  Labelled = 'labelled',
  SearchBar = 'search'
}