import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getListOfPromoCodes, getPromoCodesDetail } from '../api/subscriptions';
import { PromoCodesData } from "../pages/Dashboard/Dashboard.props";
import { RootState } from './store';

export interface PromoCodesObjectState {
  data?: PromoCodesData[];
  isLoading: boolean;
  error?: string;
}

const initialState: PromoCodesObjectState = {
  isLoading: false,
  data: undefined,
  error: undefined,
}

export const getListPromoCodes = createAsyncThunk('promoCodes/getListOfPromoCodes', async () => {
  const response = await getListOfPromoCodes();
  const data = response.lifestreamResponse.data;
  if (data) {
    const asyncRes = await Promise.all(data.map(async (item: any) => {
      try {
        const res = await getPromoCodesDetail(item.code || '');
        if (res && res.lifestreamResponse.data) {
          item.count = res.lifestreamResponse.data.count;
        }
      } catch (error: any) {
        if (error.response.status === 404) {
          item.count = 0;
        }
        console.log(error);
      }
      return item;
    }));
    return asyncRes;
  } else {
    return [];
  }
})

const promoCodesSlice = createSlice({
  name: 'promoCodes',
  initialState,
  reducers: {
    setPromoCodesData: (state, action: PayloadAction<PromoCodesData[]>) => {
      state.isLoading = false;
      state.data = action.payload;
      state.error = undefined;
    },
    setPromoCodesClear: (state) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = undefined;
    },

  },
  extraReducers: (builder) => {
    builder.addCase(getListPromoCodes.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getListPromoCodes.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload as any;
      state.error = undefined;
    });

    builder.addCase(getListPromoCodes.rejected, (state, action) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = action.payload as string;
    });
  },
});

export const {
  setPromoCodesData,
  setPromoCodesClear,
} = promoCodesSlice.actions;

export const promoCodesSelector = (state: RootState) => state.promoCodes;

export default promoCodesSlice.reducer;
