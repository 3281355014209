import {CONSTANTS} from '../constants/constants';
import {HttpMethod, httpClient} from './httpClient';

export const getEventsFromSubscriberId = (subscriberId: string): Promise<any> => {
  return httpClient(`/platform/events/${subscriberId}`, HttpMethod.Get, null, CONSTANTS.SERVICE_TYPES.PLATFORM);
}

export const getEventsFromCorrelationId = (correlationId: string): Promise<any> => {
  return httpClient(`/platform/events/correlation/${correlationId}`, HttpMethod.Get, null, CONSTANTS.SERVICE_TYPES.PLATFORM);
}

export const getEventsFromDateRange = ({from, to}: any): Promise<any> => {
  return httpClient(`/platform/events/daterange?fromDateTimeInISO=${from}&toDateTimeInISO=${to}`, HttpMethod.Get, null, CONSTANTS.SERVICE_TYPES.PLATFORM);
};