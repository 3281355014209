/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { SubscriptionsFeatures, getSubscriptionsBySubs, subscriptionsSelector } from '../../redux/subscriptionsSlice';
import { InputLabel } from '../../components/InputLabel';
import { Colors } from '../../configs/colors';
import { images } from '../../constants/images';
import { Button, Dropdown } from 'react-bootstrap';
import { getCouponsListByCode, couponsListSelector } from '../../redux/couponsListSlice';
import { Text } from '../../components/Text';
import PromoCodeItemPopup from '../../components/PromoCodeItemPopup/PromoCodeItemPopup';
import { SubscriptionsAccodion } from '../../components/Accordion/SubscriptionsAccodion';
import { Accordion } from '../../components/Accordion/Accordion';
import { durationList, modeList, currencyList } from '../../constants/rowData';
import { postSeedPromoCode, seedPromoCodeSelector } from '../../redux/seedPromoCodeSlice';
import { ToastMessage } from '../../components/ToastMessage/ToastMessage';
import { STATUS_CODE } from '../../constants/statusCodes';
import MessageBoxPopup from '../../components/MessageBoxPopup/MessageBoxPopup';

interface SubscriptionsProps { }

type existingPromoCodeDataType = {
  stripeCouponId?: string,
  code: string,
  trialPeriodInDays?: string | number,
  isTrialApplicable: boolean,
  appliesTosubscriptions?: [],
}
type CouponParams = {
  duration: string;
  durationInMonths: string;
  mode: string;
  discount: string;
  maxRedemptions: string;
};

type newPromoCodeDataType = {
  // trialPeriodInDays: number,
  isTrialApplicable: boolean,
  name: string,
  code: string,
  currency: string,
  couponParams: CouponParams,
  appliesTosubscriptions?: []
};

type MessageBoxData = {
  visibility: boolean,
  headerTextColor: string,
  headerText: string,
  messageText: string
};

export const Subscriptions: React.FC<SubscriptionsProps> = () => {
  const [showPopupPromoCodes, setShowPopupPromoCodes] = useState<boolean>(false);
  const [isExistingCopans, setIsExistingCopans] = useState<boolean>(false);
  const [coupans, setCoupans] = useState<string>('Select');
  const [appliesToChecked, setAppliesToChecked] = useState<string[]>([])
  const [showToast, setShowToast] = useState<boolean>(false)

  const initMessageBoxData = {
    visibility: false,
    headerTextColor: '',
    headerText: '',
    messageText: ''
  };

  const errorMessageBoxData = {
    visibility: true,
    headerTextColor: 'red',
    headerText: 'Error!',
    messageText: 'Please fill the required fields'
  };

  const [messageBoxData, setMessageBoxData] = useState<MessageBoxData>(initMessageBoxData);

  const newPromoCodeDataTypes: newPromoCodeDataType = {
    isTrialApplicable: false,
    currency: '',
    name: '',
    code: '',
    couponParams: {
      duration: '',
      durationInMonths: '',
      mode: '',
      discount: '',
      maxRedemptions: ''
    },
    appliesTosubscriptions: []
  }
  const [newPromoCodeData, setNewPromoCodeData] = useState<newPromoCodeDataType>(newPromoCodeDataTypes);

  const [existingPromoCodeData, setExistingPromoCodeData] = useState<existingPromoCodeDataType>({
    stripeCouponId: '',
    trialPeriodInDays: '',
    code: '',
    isTrialApplicable: false,
    appliesTosubscriptions: [],
  })



  const dispatch = useAppDispatch();
  const subscriptions = useAppSelector(subscriptionsSelector);
  const promoCodeList = useAppSelector(couponsListSelector);
  const seedPromoCode = useAppSelector(seedPromoCodeSelector);

  const appliesToHandler = (event: React.ChangeEvent<HTMLInputElement>, item: { subscriptionId: string }) => {
    let array = [...appliesToChecked];
    if (array?.length < 1) {
      array.push(item?.subscriptionId);
    } else {
      const isExist = array?.includes(item?.subscriptionId);
      const index = array?.indexOf(item?.subscriptionId);
      if (isExist) {
        array.splice(index, 1)
      } else {
        array.push(item?.subscriptionId);
      }
    }
    setAppliesToChecked(array);
  }

  const submitHandler = async () => {
    let seedJson = {};
    if (isExistingCopans) {
      if(existingPromoCodeData.code === '' || existingPromoCodeData.stripeCouponId === ''){
        setMessageBoxData(errorMessageBoxData);
        return;
      }
      seedJson = { ...existingPromoCodeData, appliesTosubscriptions: appliesToChecked }
    } else {
      if(newPromoCodeData.code === '' || newPromoCodeData.name === '' || newPromoCodeData.currency === '' ||
      newPromoCodeData.couponParams.discount === '' || newPromoCodeData.couponParams.duration === '' || 
      newPromoCodeData.couponParams.mode === ''){
        setMessageBoxData(errorMessageBoxData);
        return;
      }
      seedJson = { ...newPromoCodeData, appliesTosubscriptions: appliesToChecked }
    }
    dispatch(postSeedPromoCode(seedJson)).then((response) => {
      if (response.payload.code === STATUS_CODE.SUCCESS_CODE){
        setShowToast(true);
        setMessageBoxData({
          visibility: true,
          headerTextColor: 'green',
          headerText: 'Success!',
          messageText: `Promo Code: ${response.payload.data.code}`
        });
      }
    });

    setShowPopupPromoCodes(false);

    setExistingPromoCodeData({
      stripeCouponId: '',
      trialPeriodInDays: '',
      code: '',
      isTrialApplicable: false,
      appliesTosubscriptions: [],
    })
    setNewPromoCodeData(newPromoCodeDataTypes)
    setAppliesToChecked([])
    setIsExistingCopans(false)
    setCoupans('Select');
  }

  useEffect(() => {
    dispatch(getSubscriptionsBySubs());
    dispatch(getCouponsListByCode());
  }, []);

  return (
    <div className='card pr-2 pl-2'>
      {
        showToast && (
          <ToastMessage onHideToast={(val) => setShowToast(val)} position='top-right' message='Promocode added successfully!' type='success' />
        )
      }
      <div className="subscriptions-container">
        {!subscriptions?.isLoading && !seedPromoCode?.isLoading ? (
          <div>
            <div className=''>
              <div className='row justify-content-between align-items-center pr-3 pl-4 p-2'>
                <Text color={Colors.LSNavy} fontSize={16} fontWeight="700">
                  {'Subscriptions List'}
                </Text>
                <button type="button" className="btn btn-info" onClick={() => setShowPopupPromoCodes(true)}>Add Promocode</button>
              </div>
            </div>
            {
              subscriptions?.data &&
              subscriptions?.data.length &&
              subscriptions?.data.map((subs) => (
                <SubscriptionsAccodion key={subs.subscriptionId} title={subs?.name}>
                  <div className='border-top' />
                  <div className="container pt-2">
                    <div className='row p-2 bg-light rounded mt-2'>
                      <div className='pr-4'>
                        <Text color={Colors.NeutralBlack} fontSize={14} fontWeight="700">
                          {'Subscription ID:'}
                        </Text>
                      </div>
                      <Text color={Colors.NeutralBlack} fontSize={14} fontWeight="700">
                        {subs.subscriptionId}
                      </Text>
                    </div>
                    <div className="row align-items-start px-4">
                      <InputLabel
                        type="text"
                        disabledInput={true}
                        label="Short Name:"
                        value={subs?.shortName || 'N/A'}
                        widthInput="calc(100% - 116px)"
                        widthInputLabel="180px"
                      />
                      <InputLabel
                        type="text"
                        disabledInput={true}
                        label="Type:"
                        value={subs.type || 'N/A'}
                        widthInput="calc(100% - 116px)"
                        widthInputLabel="180px"
                      />
                      <InputLabel
                        type="text"
                        disabledInput={true}
                        label="Trial Period:"
                        value={subs.trialPeriodInDays || 'N/A'}
                        widthInput="calc(100% - 116px)"
                        widthInputLabel="180px"
                      />
                      <InputLabel
                        type="text"
                        disabledInput={true}
                        label="Message Template:"
                        value={subs?.messageTemplate || 'N/A'}
                        widthInput="calc(100% - 116px)"
                        widthInputLabel="180px"
                      />
                      <InputLabel
                        type="text"
                        disabledInput={true}
                        label="Basic:"
                        value={subs.isBasic ? 'Yes' : 'No' || 'N/A'}
                        widthInput="calc(100% - 116px)"
                        widthInputLabel="180px"
                      />
                      <InputLabel
                        type="text"
                        disabledInput={true}
                        label="Default:"
                        value={subs.isDefault ? 'Yes' : 'No' || 'N/A'}
                        widthInput="calc(100% - 116px)"
                        widthInputLabel="180px"
                      />
                      <InputLabel
                        type="text"
                        disabledInput={true}
                        label="Description:"
                        value={subs?.description || 'N/A'}
                        widthInput="calc(100% - 116px)"
                        widthInputLabel="180px"
                      />
                    </div>
                  </div>
                  <div className="container pt-2">
                    <div className='row p-2 bg-light rounded mt-2'>
                      <Text color={Colors.NeutralBlack} fontSize={14} fontWeight="700">
                        {'Default Price'}
                      </Text>
                    </div>
                    <div className="row align-items-start px-4">
                      <InputLabel
                        type="text"
                        disabledInput={true}
                        label="Unit Amount:"
                        value={subs.defaultPrice.unitAmountFormatted || 'N/A'}
                        widthInput="calc(100% - 116px)"
                        widthInputLabel="180px"
                      />
                      <InputLabel
                        type="text"
                        disabledInput={true}
                        label="Currency:"
                        value={subs?.defaultPrice?.currency || 'N/A'}
                        widthInput="calc(100% - 116px)"
                        widthInputLabel="180px"
                      />
                      <InputLabel
                        type="text"
                        disabledInput={true}
                        label="Recurring Interval:"
                        value={subs?.defaultPrice?.recurringInterval || 'N/A'}
                        widthInput="calc(100% - 116px)"
                        widthInputLabel="180px"
                      />
                      <InputLabel
                        type="text"
                        disabledInput={true}
                        label="Type:"
                        value={subs?.defaultPrice?.type || 'N/A'}
                        widthInput="calc(100% - 116px)"
                        widthInputLabel="180px"
                      />
                      <InputLabel
                        type="text"
                        disabledInput={true}
                        label="Price ID:"
                        value={subs?.defaultPrice?.priceId || 'N/A'}
                        widthInput="calc(100% - 116px)"
                        widthInputLabel="180px"
                      />
                    </div>
                  </div>
                  <div className="container pt-2">
                    <div className='row bg-light rounded mt-2 p-2'>
                      <Text color={Colors.NeutralBlack} fontSize={14} fontWeight="700">
                        {'Features'}
                      </Text>
                    </div>
                    <div className="row align-items-start px-4">
                      <div className='pb-4 w-100'>
                        {subs.features.map((item: SubscriptionsFeatures, index: number) => (
                          <Accordion key={item.featureId} title={item.featureName} icon={item.image} featureCode={item.featureCode} ContainerTextStyle={{ color: Colors.LSBlack, fontWeight: 'bold', fontSize: '15px' }} ContainerListStyle={{ borderBottomColor: 'rgba(0,0,0,0.1)' }}>
                            <div className="accordion-content-item" key={index}>
                              <div className="text-content py-2">
                                <Text fontWeight="400" fontSize={15} color={Colors.NeutralGrey2}>
                                  {item.description}
                                </Text>
                              </div>
                            </div>
                          </Accordion>
                        ))}
                      </div>
                    </div>
                  </div>
                </SubscriptionsAccodion>
              ))}
            {
              showPopupPromoCodes && (
                <div>
                  <PromoCodeItemPopup
                    OnClick={async () => { setShowPopupPromoCodes(false) }}
                    show={showPopupPromoCodes}
                  >
                    {
                      <>
                        <>
                          <div className='d-flex pl-3 p-2 align-items-center'>
                            <div className='w-25'>
                              <span className='text-black-50'>{'Coupans:'}</span>
                            </div>
                            <div className='d-flex'>
                              <div className='d-flex justify-content-center align-items-center'>
                                <input style={{ height: '20px', width: '20px', accentColor: Colors.LSDarkTeal }} checked={isExistingCopans} onChange={(event) => setIsExistingCopans(event.target.checked)}
                                  type={'checkbox'} value="test" />
                                <span className='px-2'>Attach to Existing Coupans</span>
                              </div>
                              <div>
                                {
                                  isExistingCopans &&
                                  <Dropdown data-bs-theme="dark">
                                    <Dropdown.Toggle id="dropdown-button-dark-example1" variant="outline-info">
                                      {coupans}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <Dropdown.Item onClick={() => setCoupans('Select')}>
                                        {'Select'}
                                      </Dropdown.Item>
                                      {promoCodeList?.data?.map(item => {
                                        return (
                                          <Dropdown.Item key={item.id} onClick={() => {
                                            setExistingPromoCodeData({ ...existingPromoCodeData, stripeCouponId: item.id })
                                            setCoupans(item.name)
                                          }}>
                                            {item.name}
                                          </Dropdown.Item>
                                        )
                                      })}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                }
                              </div>
                            </div>
                          </div>
                          {
                            isExistingCopans ? (
                              <>
                                <div className='d-flex pl-3 p-2 align-items-center'>
                                  <div className='w-25'>
                                    <span className='text-black-50'>{'Stripe Coupon Id *:'}</span>
                                  </div>
                                  <div className='w-75 pr-2'>
                                    <input disabled className='w-100 form-control' type={'text'} value={existingPromoCodeData.stripeCouponId} onChange={(event) => setExistingPromoCodeData({ ...existingPromoCodeData, stripeCouponId: event.target.value })} />
                                  </div>
                                </div>
                                <div className='d-flex pl-3 p-2 align-items-center'>
                                  <div className='w-25'>
                                    <span className='text-black-50'>{'Promo Code *:'}</span>
                                  </div>
                                  <div className='w-75 pr-2'>
                                    <input className='w-100 form-control' type={'text'} value={existingPromoCodeData.code} onChange={(event) => setExistingPromoCodeData({ ...existingPromoCodeData, code: event.target.value })} />
                                  </div>
                                </div>

                                <div className='d-flex pl-3 p-2 align-items-center'>
                                  <div className='w-25'>
                                    <span className='text-black-50'>{'Trial Period In Days:'}</span>
                                  </div>
                                  <div className='w-75 pr-2'>
                                    <input className='w-100 form-control' type={'number'} value={existingPromoCodeData.trialPeriodInDays} onChange={(event) => setExistingPromoCodeData({ ...existingPromoCodeData, trialPeriodInDays: event.target.value })} />
                                  </div>
                                </div>

                                <div className='d-flex pl-3 p-2 align-items-center'>
                                  <div className='w-25'>
                                    <span className='text-black-50'>{'Is Trial Applicable'}</span>
                                  </div>
                                  <div className=''>
                                    <input style={{ height: '20px', width: '20px', accentColor: Colors.LSDarkTeal }} className='' type={'checkbox'}
                                      checked={existingPromoCodeData.isTrialApplicable} onChange={(event) => setExistingPromoCodeData({ ...existingPromoCodeData, isTrialApplicable: event.target.checked })} />
                                  </div>
                                </div>
                              </>
                            ) :
                              (
                                <>
                                  <div className='d-flex pl-3 p-2 align-items-center'>
                                    <div className='w-25'>
                                      <span className='text-black-50'>{'Name *:'}</span>
                                    </div>
                                    <div className='w-75 pr-2'>
                                      <input className='w-100 form-control' type={'text'} value={newPromoCodeData.name} onChange={(event) => setNewPromoCodeData({ ...newPromoCodeData, name: event.target.value })} />
                                    </div>
                                  </div>

                                  <div className='d-flex pl-3 p-2 align-items-center'>
                                    <div className='w-25'>
                                      <span className='text-black-50'>{'Code *:'}</span>
                                    </div>
                                    <div className='w-75 pr-2'>
                                      <input className='w-100 form-control' value={newPromoCodeData.code} onChange={(event) => setNewPromoCodeData({ ...newPromoCodeData, code: event.target.value })} type={'number'} />
                                    </div>
                                  </div>

                                  <div className='d-flex pl-3 p-2 align-items-center'>
                                    <div className='w-25'>
                                      <span className='text-black-50'>{'Duration *:'}</span>
                                    </div>
                                    <div className='w-75 pr-2'>
                                      <Dropdown data-bs-theme="dark">
                                        <Dropdown.Toggle id="dropdown-button-dark-example1" variant="outline-info">
                                          {newPromoCodeData.couponParams.duration ? newPromoCodeData.couponParams.duration : 'Select'}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <Dropdown.Item onClick={() => setNewPromoCodeData({
                                            ...newPromoCodeData, couponParams: {
                                              ...newPromoCodeData.couponParams,
                                              duration: 'Select',
                                            },
                                          })}>
                                            {'Select'}
                                          </Dropdown.Item>
                                          {durationList?.map(item => {
                                            return (
                                              <Dropdown.Item key={item.id} onClick={() => setNewPromoCodeData({
                                                ...newPromoCodeData, couponParams: {
                                                  ...newPromoCodeData.couponParams,
                                                  duration: item.name.toLowerCase(),
                                                },
                                              })}>
                                                {item.name}
                                              </Dropdown.Item>
                                            )
                                          })}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>

                                  <div className='d-flex pl-3 p-2 align-items-center'>
                                    <div className='w-25'>
                                      <span className='text-black-50'>{'Duration In Months *:'}</span>
                                    </div>
                                    <div className='w-75 pr-2'>
                                      <input className='w-100 form-control' type={'text'} value={newPromoCodeData.couponParams.durationInMonths} onChange={(event) => setNewPromoCodeData({
                                        ...newPromoCodeData, couponParams: {
                                          ...newPromoCodeData.couponParams, durationInMonths: event.target.value
                                        }
                                      })} />
                                    </div>
                                  </div>

                                  <div className='d-flex pl-3 p-2 align-items-center'>
                                    <div className='w-25'>
                                      <span className='text-black-50'>{'Mode *:'}</span>
                                    </div>
                                    <div className='w-75 pr-2'>
                                      <Dropdown data-bs-theme="dark">
                                        <Dropdown.Toggle id="dropdown-button-dark-example1" variant="outline-info">
                                          {newPromoCodeData.couponParams.mode ? newPromoCodeData.couponParams.mode : 'Select'}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <Dropdown.Item onClick={() => setNewPromoCodeData({
                                            ...newPromoCodeData, couponParams: {
                                              ...newPromoCodeData.couponParams, mode: 'Select'
                                            }
                                          })}>
                                            {'Select'}
                                          </Dropdown.Item>
                                          {modeList?.map(item => {
                                            return (
                                              <Dropdown.Item key={item.id} onClick={() => setNewPromoCodeData({
                                                ...newPromoCodeData, couponParams: {
                                                  ...newPromoCodeData.couponParams, mode: item.name
                                                }
                                              })}>
                                                {item.name}
                                              </Dropdown.Item>
                                            )
                                          })}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>

                                  <div className='d-flex pl-3 p-2 align-items-center'>
                                    <div className='w-25'>
                                      <span className='text-black-50'>{'Max Redemptions:'}</span>
                                    </div>
                                    <div className='w-75 pr-2'>
                                      <input className='w-100 form-control' type={'text'} value={newPromoCodeData.couponParams.maxRedemptions} onChange={(event) => setNewPromoCodeData({
                                        ...newPromoCodeData, couponParams: {
                                          ...newPromoCodeData.couponParams, maxRedemptions: event.target.value
                                        }
                                      })} />
                                    </div>
                                  </div>

                                  <div className='d-flex pl-3 p-2 align-items-center'>
                                    <div className='w-25'>
                                      <span className='text-black-50'>{'Currency *:'}</span>
                                    </div>
                                    <div className='w-75 pr-2'>
                                      <Dropdown data-bs-theme="dark" className='w-100'>
                                        <Dropdown.Toggle id="dropdown-button-dark-example1" variant="outline-info">
                                          {newPromoCodeData.currency ? newPromoCodeData.currency : 'Select'}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <Dropdown.Item onClick={() => setNewPromoCodeData({ ...newPromoCodeData, currency: 'Select' })}>
                                            {'Select'}
                                          </Dropdown.Item>
                                          {currencyList?.map(item => {
                                            return (
                                              <Dropdown.Item key={item.id} onClick={() => setNewPromoCodeData({ ...newPromoCodeData, currency: item.name })}>
                                                {item.name}
                                              </Dropdown.Item>
                                            )
                                          })}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>

                                  <div className='d-flex pl-3 p-2 align-items-center'>
                                    <div className='w-25'>
                                      <span className='text-black-50'>{'Discount *:'}</span>
                                    </div>
                                    <div className='w-75 pr-2'>
                                      <input className='w-100 form-control' type={'text'} value={newPromoCodeData.couponParams.discount} onChange={(event) => setNewPromoCodeData({
                                        ...newPromoCodeData, couponParams: {
                                          ...newPromoCodeData.couponParams, discount: event.target.value
                                        }
                                      })} />
                                    </div>
                                  </div>
                                </>
                              )
                          }
                        </>
                        <div className='p-4 align-items-center'>
                          <div className='py-2'>
                            <span className='text-black-50'>{'Applies to:'}</span>
                          </div>
                          {
                            subscriptions?.data?.map(item => (
                              <div key={item.subscriptionId} className='d-flex align-items-center'>
                                <input checked={appliesToChecked?.includes(item.subscriptionId)} style={{ height: '20px', width: '20px', accentColor: Colors.LSDarkTeal }} className='m-2' type={'checkbox'} onChange={(event) => appliesToHandler(event, item)} />
                                <span>{item.name}</span>
                              </div>
                            ))
                          }
                        </div>
                        <div className='row justify-content-end m-2'>
                          <Button onClick={() => submitHandler()} title='submit' variant="info">SUBMIT</Button>
                        </div>
                      </>
                    }
                  </PromoCodeItemPopup>
                </div>
              )}
          </div>)
          : (
            <div className="d-flex justify-content-center">
              <div className="loader-image">
                <img src={images.Spinner} alt="" />
              </div>
            </div>
          )}
      </div>
      {
        messageBoxData.visibility && <>
        <MessageBoxPopup show ={messageBoxData.visibility} headerTextColor = {messageBoxData.headerTextColor} OnCloseClick={async () => { setMessageBoxData(initMessageBoxData)}} headerTest= {messageBoxData.headerText}>{messageBoxData.messageText}</MessageBoxPopup>
        </>
      }
    </div>
  );
};
