/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SearchBar } from '../../components/SearchBar/SearchBar';
import { images } from '../../constants/images';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { membershipListForPromocode, membershipListForPromocodeSelector } from '../../redux/membershipsForPromocodeSlice';
import { getMembershipCountForPromocode, getPromoCodeList, promoCodeListSelector } from '../../redux/promoCodeListSlice';
import { MembershipData, PromoCodesData } from './Dashboard.props';
import './Dashboard.style.css';

interface DashboardProps { }

const dataHeader = [
  {
    type: '#',
    widthType: '30%',
  },
  {
    type: 'Id',
    widthType: '50%',
  },
  {
    type: 'Number of enrollments',
    widthType: '20%',
  },
];

const promocodedata = {
  membershipCount: '',
  name: '',
  mode: '',
  duration: '',
  discount: '',
  code: ''
};

interface MembershipListData {
  data: MembershipData[];
  nextPage: boolean;
  prevPage: boolean;
  pagekey?: string;
  index: number;
  pageLength: number;
}

export const Dashboard: React.FC<DashboardProps> = () => {
  const navigate = useNavigate();
  const [promoCodesData, setPromoCodeData] = useState<PromoCodesData>();
  const [showPopupPromoCodes, setShowPopupPromoCodes] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const promocodeListSelect = useAppSelector(promoCodeListSelector);
  //::TODO::This will be removed later after the testing is done in CI
  //const promoCodesEventData = useAppSelector(promoCodesSelector);
  const membershipForPromocodeSelect = useAppSelector(membershipListForPromocodeSelector);
  const [promocodeData, setPromocodeData] = useState<PromoCodesData>();
  const [selectedPromocodeValue, setSelectedPromocodeValue] = useState<string>();
  const [membershipDataList, setMembershipDataList] = useState<MembershipListData[]>([]);
  const [selectedMembershipDataPage, setSelectedMembershipDataPage] = useState<MembershipListData>();
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [startDateTime, setStartDateTime] = useState<string>('');
  const [endDateTime, setEndDateTime] = useState<string>('');

  //::TODO::This will be removed later after the testing is done in CI
  // useEffect(() => {
  //   dispatch(getListPromoCodes());
  // }, []);

  // const handleShowPopUpPromoCodes = (data: PromoCodesData) => {
  //   setPromoCodeData(data);
  //   setShowPopupPromoCodes(true);
  // };

  const resetDashboardData = () => {
    setSelectedPromocodeValue('');
    setPromocodeData(undefined);
    setMembershipDataList([]);
    setSelectedMembershipDataPage(undefined);
  };

  const onSearchPress = (value: string) => {
    resetDashboardData();
    setSelectedPromocodeValue(value);
    if (promocodeListSelect.data) {
      setSelectedPromocode(value);
    } else {
      dispatch(getPromoCodeList());
    }
  };

  const setSelectedPromocode = (promocodeValue: string) => {
    promocodeListSelect.data?.some((promocode) => {
      if (promocode.code === promocodeValue) {
        setPromocodeData(promocode);
        dispatch(getMembershipCountForPromocode(promocode.promoCodeId!)).then((membershipCount) => {
          setPromocodeData({ ...promocode, count: membershipCount.payload });
        });
        return true;
      } else {
        return false;
      }
    });
  };

  const showSubscriberList = () => {
    if (promocodeData) {
      setEndDateTime('');
      setStartDateTime('');
      setMembershipDataList([]);
      dispatch(membershipListForPromocode({ promocodeId: promocodeData.promoCodeId! }));
    }
  };

  const filterSubscriberList = () => {
    if (promocodeData) {
      setMembershipDataList([]);
      dispatch(membershipListForPromocode({ promocodeId: promocodeData.promoCodeId!, fromDate: startDateTime, toDate: endDateTime }));
    }
  };

  const loadMembershipListPage = (direction: string) => {
    if (selectedMembershipDataPage) {
      if (direction === 'next') {
        if (selectedMembershipDataPage?.index + 1 < membershipDataList.length) {
          setSelectedMembershipDataPage(membershipDataList[selectedMembershipDataPage.index + 1]);
        } else {
          dispatch(membershipListForPromocode({ promocodeId: promocodeData?.promoCodeId!, pageKey: selectedMembershipDataPage.pagekey, fromDate:startDateTime, toDate:endDateTime }));
        }
      } else {
        setSelectedMembershipDataPage(membershipDataList[selectedMembershipDataPage.index - 1]);
      }
    }
  };

  useEffect(() => {
    if (promocodeListSelect.data) {
      setSelectedPromocode(selectedPromocodeValue!);
    }
  }, [promocodeListSelect.data]);

  useEffect(() => {
    if (membershipForPromocodeSelect.data) {
      const membershipDataLength = membershipDataList.length;
      const pageLength = membershipDataLength > 0 ? membershipDataList[0].data.length : membershipForPromocodeSelect.data.data.length;
      const membershipData: MembershipListData = {
        data: membershipForPromocodeSelect.data.data,
        nextPage: membershipForPromocodeSelect.data.nextPage,
        prevPage: membershipDataLength > 0 ? true : false,
        pagekey: membershipForPromocodeSelect.data.pagekey,
        index: membershipDataLength > 0 ? membershipDataLength : 0,
        pageLength: pageLength
      };
      membershipDataList.push(membershipData);
      setSelectedMembershipDataPage(membershipData);
      setMembershipDataList([...membershipDataList]);
    }
  }, [membershipForPromocodeSelect.data]);

  const handleFilterToggle = () => {
    setShowFilter((current) => !current);
  }

  return (
    <div className="dashboard">
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
            <div className="col-xxl-4 col-md-12">
              <div className="card info-card sales-card">
                <div className="filter">
                  <a className="icon" data-bs-toggle="dropdown">
                    <i className="bi bi-three-dots"></i>
                  </a>
                  <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                    <li className="dropdown-header text-start">
                      <h6>Filter</h6>
                    </li>

                    <li>
                      <a className="dropdown-item" href="#">
                        Today
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        This Month
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        This Year
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="card-body">
                  <h5 className="card-title">
                    Memberships for Promocodes <span>| Till Date</span>
                  </h5>
                  <div className="search-wrapper">
                    <SearchBar onSearchPress={(value) => onSearchPress(value)} placeholder='Search Promocode' label='Enter Promocode' />
                  </div>
                  {/* This will be removed later after the testing is done in CI */}
                  {/* <div className="table promocode-table">
                    <TableHeader dataHeader={dataHeader} />
                    <div className="table-content scroll-custom">
                      {(!promoCodesEventData.data || promoCodesEventData.data.length === 0) &&
                        !promoCodesEventData.isLoading && (
                          <div className="no-data">No Data Available</div>
                        )}
                      {!promoCodesEventData.isLoading ? (
                        promoCodesEventData.data &&
                        promoCodesEventData.data.map((item: PromoCodesData) => (
                          <div key={item.promoCodeId}>
                            <div className="table-row-content" id={`tr-${item.promoCodeId}`}>
                              <div
                                className="link-color"
                                style={{ width: dataHeader[0].widthType }}
                                onClick={() => handleShowPopUpPromoCodes(item)}
                              >
                                {item.code}
                              </div>
                              <div style={{ width: dataHeader[1].widthType }}>
                                {item.promoCodeId}
                              </div>
                              <div
                                className="link-color"
                                style={{ width: dataHeader[2].widthType }}
                                onClick={() => navigate('/promocodes/' + item.code)}
                              >
                                {item.count}
                              </div>
                            </div>
                            {showPopupPromoCodes &&
                              promoCodesData?.promoCodeId === item.promoCodeId && (
                                <PromoCodesPopup
                                  detailEvent={promoCodesData}
                                  onClose={(value) => setShowPopupPromoCodes(value)}
                                />
                              )}
                          </div>
                        ))
                      ) : (
                        <div className="d-flex justify-content-center">
                          <div className="loader-image">
                            <img src={images.Spinner} alt="" />
                          </div>
                        </div>
                      )}
                    </div>
                  </div> */}
                  {promocodeListSelect.isLoading ? <>
                    <div className="col-xxl-4 col-xl-12">
                      <div className="card info-card customers-card">
                        <div className="card-body">
                          <div className="d-flex justify-content-center">
                            <div className="loader-image">
                              <img src={images.Spinner} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </> : <>{promocodeData &&
                    <div className="col-xxl-4 col-md-4 p-0">
                      <div className="card info-card customers-card">
                        <div className="card-body">
                          {/* <h5 className="card-title">
                      Promocode <span>| {promocodeData?.code}</span>
                    </h5> */}

                          <div className="d-flex align-items-center">
                            <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                              <i className="bi bi-people"></i>
                            </div>
                            <div className="ps-3 promocode">
                              <span className="promo-card-title card-span">{promocodeData?.count}</span>
                              <span className="promo-card-name card-span text-muted">{promocodeData?.name}</span>
                              <span className="promo-card-description card-span text-muted">
                                <span className="text-danger">{promocodeData?.duration}</span> {' '}
                                {promocodeData?.mode === 'Percentage' ? promocodeData?.discount + '% off' : '$' + promocodeData?.discount + ' off'}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>}
                  </>}

                  {promocodeData && <div className="col-12 p-0">
                    <div className="card recent-sales overflow-auto">
                      <div className="card-body subscriber-list">
                        <button className="subscriber-list-btn" onClick={() => showSubscriberList()}>
                          Show Subscriber List
                        </button>
                        <div className="membership-table-wrapper">
                          {membershipForPromocodeSelect.isLoading ? <>
                            <div className="col-xxl-4 col-xl-12">
                              <div className="card info-card customers-card">
                                <div className="card-body">
                                  <div className="d-flex justify-content-center">
                                    <div className="loader-image">
                                      <img src={images.Spinner} alt="" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </> : <>{selectedMembershipDataPage &&
                            <>

                              <div className="col-xl-8 p-0">
                                <div className="card mt-2 " onClick={() => handleFilterToggle()}>
                                  <div className='card-for-filter p-3 d-flex justify-content-end'>
                                    <span>Filter &nbsp; {showFilter === false ? <><i className='bi bi-chevron-down' ></i> </> : <><i className='bi bi-chevron-up'></i></>}</span>
                                  </div>
                                </div>
                              </div>
                              {showFilter === true ? <>
                                <div className="col-xl-8 p-0 top">
                                  <div className="card" >
                                    <div className='card-for-filter p-3 '>
                                      <div className="">
                                        <div className="row p-3">
                                          <div className="col-md-6">
                                            <label htmlFor="start-date">Start date and time</label>

                                            <div className="mr-2">
                                              <input className='form-control' value={startDateTime} onChange={(e) => setStartDateTime(e.target.value)} type="datetime-local" />
                                            </div>
                                          </div>
                                          <div className="col-md-6">
                                            <label htmlFor="start-date">End date and time</label>
                                            <div className="mr-2">
                                              <input className='form-control' value={endDateTime} onChange={(e) => setEndDateTime(e.target.value)} type="datetime-local" />
                                            </div>
                                          </div>
                                          <div className='mt-2 p-0 for-ok-btn d-flex justify-content-end' style={{ width: '97%' }}>
                                            <button className="ok-btn" type='button' onClick={() => filterSubscriberList()}  >
                                              Ok
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div></> : <></>}

                              <table className="table table-borderless datatable">
                                <thead>
                                  <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Membership</th>
                                    <th scope="col">Date</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    selectedMembershipDataPage && selectedMembershipDataPage.data.map((item, index) => (
                                      <tr key={index + item.subscriberId}>
                                        <th scope="row">
                                          <a href="#">
                                            #{(selectedMembershipDataPage.index) * selectedMembershipDataPage.pageLength + (index + 1)}
                                          </a>
                                        </th>
                                        <td>{item.firstName} {item.lastName}</td>
                                        <td>
                                          <a href="#" className="text-primary">
                                            {item.email}
                                          </a>
                                        </td>
                                        <td>{item.membership}</td>
                                        <td>
                                          <span>{item.createdDate}</span>
                                        </td>
                                      </tr>
                                    ))
                                  }
                                </tbody>
                              </table>
                              {selectedMembershipDataPage.prevPage && <button className="page-btn" onClick={() => loadMembershipListPage('previous')}>Previous</button>}
                              {selectedMembershipDataPage.nextPage && <button className="page-btn" onClick={() => loadMembershipListPage('next')}>Next</button>}
                            </>
                          }</>}
                        </div>
                      </div>
                    </div>
                  </div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
