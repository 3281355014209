import React, { useEffect, useState } from 'react';
import './DatePicker.styles.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
// you will need the css that comes with bootstrap@3. if you are using
// a tool like webpack, you can do the following:
import 'bootstrap/dist/css/bootstrap.css';
// you will also need the css that comes with bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';

interface DatePickerProps {
  setShowDatePicker: (value: boolean) => void;
  onApplyFromDateToDate: (fromDate: Date, toDate: Date) => void;
  onClearValue: () => void;
}

const initialSettings = {
  startDate: moment().startOf('hour'),
  endDate: moment().startOf('hour').add(12, 'hour'),
  opens: 'left',
  timePicker: true,
  alwaysShowCalendars: true,
  applyButtonClasses: 'btn-date-picker-apply',
  container: '#range-dateTimes',
  timePicker24Hour: true,
  timePickerSeconds: true,
  locale: {
    format: 'YYYY-MM-DD hh:mm:ss',
    separator: ' > ',
  },
  template: `<div class="daterangepicker">
    <div class="ranges"></div>
    <div class="drp-calendar left">
      <div class="calendar-table"></div>
      <div class="calendar-time"></div>
    </div>
    <div class="drp-calendar right">
      <div class="calendar-table"></div>
      <div class="calendar-time"></div>
    </div>
    <div class="drp-buttons">
    <button id="clearBtn" class="clearBtn btn btn-sm btn-clear-date-picker" type="button">Clear</button>
      <button class="cancelBtn" type="button"></button>
      <button class="applyBtn" disabled="disabled" type="button"></button> 
    </div>
  </div>`,
};

export const DatePicker: React.FC<DatePickerProps> = ({
  setShowDatePicker,
  onApplyFromDateToDate,
  onClearValue,
}) => {
  const [rangeDateTimes, setRangeDateTimes] = useState<string>('');

  useEffect(() => {
    const clearButton = document.getElementById('clearBtn');
    clearButton?.addEventListener('click', () => {
      setShowDatePicker(false);
      setRangeDateTimes('');
      onClearValue();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onApply = (event: any, picker: any) => {
    const fromDate = moment(picker.startDate).format('YYYY-MM-DD HH:mm:ss');
    const toDate = moment(picker.endDate).format('YYYY-MM-DD HH:mm:ss');
    setRangeDateTimes(`${fromDate}  >  ${toDate}`);
    setShowDatePicker(true);
    setTimeout(() => {
      if (picker.startDate && picker.endDate) {
        onApplyFromDateToDate(picker.startDate, picker.endDate);
      }
    }, 0);
  };

  const onHide = () => {
    if (rangeDateTimes === '') {
      setShowDatePicker(false);
    }
  };

  return (
    <div className="date-picker-style">
      <DateRangePicker initialSettings={initialSettings} onHide={onHide} onApply={onApply}>
        <div id="range-dateTimes" className="range-date-times">
          <input
            id="date-range-input"
            value={rangeDateTimes}
            placeholder={'Start Date  >  End Date'}
            onChange={(e) => e.preventDefault()}
          />
          <i className="bi bi-calendar"></i>
        </div>
      </DateRangePicker>
    </div>
  );
};
