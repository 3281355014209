import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getFirstAidTips, getFirstAidTipsBySeedData } from '../api/emergencyNumbers';
import { firstAidItemsData } from '../utils/dataPreparationHelper';
import { RootState } from './store';
export interface Failures {
  item: string;
  error: string;
}
export interface FirstAidItemsData {
  companyId: string;
  countryCode: string;
  createdDate: string;
  updatedDate: string;
  firstAidItems: FirstAidItemsRes[]
}

export interface FirstAidItemsRes {
  displayText: string;
  id: string;
  createdDate: string;
  updatedDate: string;
  displayOrder: number;
  components: any;
}
export interface FirstAidItemsObjectState {
  data?: FirstAidItemsData;
  isLoading: boolean;
  error?: string;
}

const initialState: FirstAidItemsObjectState = {
  isLoading: false,
  data: undefined,
  error: undefined,
}

export const getFirstAidSeedData = createAsyncThunk('firstAidItems/seed-data', async () => {
  const response = await getFirstAidTipsBySeedData();
  const data = response.lifestreamResponse.data;
  return {
    companyId: data.firstAidTips.companyId,
    firstAidItems: firstAidItemsData(data.firstAidTips.firstAidItems),
  };
});

export const getFirstAidItems = createAsyncThunk('firstAidItems/getFirstAidItems', async () => {
  const response = await getFirstAidTips();
  const data = response.lifestreamResponse.data;
  return {
    companyId: data.companyId,
    firstAidItems: firstAidItemsData(data.firstAidItems),
  }
});

const firstAidItemsSlice = createSlice({
  name: 'firstAidItems',
  initialState,
  reducers: {
    setFirstAidItemsData: (state, action) => {
      state.isLoading = false;
      state.data = action.payload as FirstAidItemsData;
      state.error = undefined;
    },
    setFirstAidItemsClear: (state) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = undefined;
    },

  },
  extraReducers: (builder) => {
    builder.addCase(getFirstAidItems.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getFirstAidItems.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload as FirstAidItemsData;
      state.error = undefined;
    });

    builder.addCase(getFirstAidItems.rejected, (state, action) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = action.payload as string;
    });

    builder.addCase(getFirstAidSeedData.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getFirstAidSeedData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload as FirstAidItemsData;
      state.error = undefined;
    });

    builder.addCase(getFirstAidSeedData.rejected, (state, action) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = action.payload as string;
    });
  },
});

export const {
  setFirstAidItemsData,
  setFirstAidItemsClear,
} = firstAidItemsSlice.actions;

export const firstAidItemsSelector = (state: RootState) => state.firstAidItems;

export default firstAidItemsSlice.reducer;
