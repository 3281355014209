import React from 'react';
import {Colors} from '../../configs/colors';
import {TextProps} from './Text.props';
import './Text.styles.css';

export const Text: React.FC<TextProps> = ({
  children,
  fontSize = 14,
  fontWeight = '400',
  color = Colors.NeutralGrey1,
  margin = '0',
  lineHeight,
  className = '',
}) => {
  return (
    <div className={`text-style ${className}`} style={{fontSize, fontWeight, color, margin, lineHeight}}>
      {children}
    </div>
  );
};
