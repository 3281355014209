/* eslint-disable @typescript-eslint/no-explicit-any */
import {CONSTANTS} from '../constants/constants';
import {HttpMethod, httpClient} from './httpClient';


export const getSubscriberById = (id: string): Promise<any> => {
  return httpClient(`/subscribers?id=${id}`, HttpMethod.Get, null, CONSTANTS.SERVICE_TYPES.SUBSCRIBERS);
};

export const getSubscriberByPhoneNumber = (phoneNumber: string): Promise<any> => {
  return httpClient(`/subscribers/account/${phoneNumber}`, HttpMethod.Get, null, CONSTANTS.SERVICE_TYPES.SUBSCRIBERS);
};

export const subscriberSearch = (searchText: string) : Promise<any> =>  {
  return httpClient(`/subscriber/subscribers/search?searchtext=${searchText}`, HttpMethod.Get, null, CONSTANTS.SERVICE_TYPES.SUBSCRIBERS)
}

export const deactiveSubscriber = (subscriberId:string) : Promise<any> => {
  return httpClient(`/subscriber/subscribers/${subscriberId}/deactivate`, HttpMethod.Delete, null, CONSTANTS.SERVICE_TYPES.SUBSCRIBERS)
}

export const getNumberOfGroupsById = (id: string): Promise<any> => {
  return httpClient(`/subscriber/${id}/groups`, HttpMethod.Get, null, CONSTANTS.SERVICE_TYPES.SUBSCRIBERS);
};