import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getSubscriptionsBySubscriberId } from '../api/subscriptions';
import { RootState } from './store';

export interface SubscriptionsObjectState {
  data?: SubscriptionsData;
  isLoading: boolean;
  error?: string;
}

export interface Features {
  image: string;
  isLoading: boolean;
  description: string;
  featureId: string;
  name: string;
}

export interface SubscriptionsData {
  memberLimit: number;
  subscriptionId: string;
  status: string;
  name: string;
  currency: string;
  deviceLimit: number;
  membershipBy: string;
  amount: number;
  stripePriceId: string;
  type: string;
  region: string;
  sign: string;
  period: string;
  features?: Features[];
}

const initialState: SubscriptionsObjectState = {
  isLoading: false,
  data: undefined,
  error: undefined,
}



export const getSubscriptionsBySubscriber = createAsyncThunk('subscription/bySubscriberId', async (subscriberId: string) => {
  const response = await getSubscriptionsBySubscriberId(subscriberId);
  const data = response.lifestreamResponse.data;
  return data;
})

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setSubscriptionData: (state, action: PayloadAction<SubscriptionsData>) => {
      state.isLoading = false;
      state.data = action.payload;
      state.error = undefined;
    },
    setSubscriptionClear: (state) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = undefined;
    },

  },
  extraReducers: (builder) => {
    builder.addCase(getSubscriptionsBySubscriber.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getSubscriptionsBySubscriber.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload as any;
      state.error = undefined;
    });

    builder.addCase(getSubscriptionsBySubscriber.rejected, (state, action) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = action.payload as string;
    });
  },
});

export const {
  setSubscriptionData,
  setSubscriptionClear,
} = subscriptionSlice.actions;

export const subscriptionSelector = (state: RootState) => state.subscription;

export default subscriptionSlice.reducer;