import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';

export interface userProfileState {
  isOverview: boolean,
  isEditProfile: boolean,
  isSetting: boolean,
  isChangePass: boolean,
}

const initialState: userProfileState = {
  isOverview: true,
  isEditProfile: false,
  isSetting: false,
  isChangePass: false,
};

const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    setShowTabOverview: (state) => {
      state.isOverview = true;
      state.isEditProfile = false;
      state.isSetting = false;
      state.isChangePass = false;
    },
    setShowTabEditProfile: (state) => {
      state.isOverview = false;
      state.isEditProfile = true;
      state.isSetting = false;
      state.isChangePass = false;
    },
    setShowTabSetting: (state) => {
      state.isOverview = false;
      state.isEditProfile = false;
      state.isSetting = true;
      state.isChangePass = false;
    },
    setShowTabChangePass: (state) => {
      state.isOverview = false;
      state.isEditProfile = false;
      state.isSetting = false;
      state.isChangePass = true;
    },
  }
});

export const {
  setShowTabOverview,
  setShowTabEditProfile,
  setShowTabSetting,
  setShowTabChangePass
} = userProfileSlice.actions;

export const UserProfileSelector = (state: RootState) => state.userProfile;

export default userProfileSlice.reducer;