import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { subscriberSearch } from "../api/subscribers";
import { RootState } from "./store";
import { subscriberSearchData } from "../pages/SubscriberData/SubscriberData.props";

export interface subscriberSearchDataObjectState {
    data?: subscriberSearchData[];
    isLoading: boolean;
    error?: string;

}

const initialState: subscriberSearchDataObjectState = {
    isLoading: false,
    data: [],
    error: undefined,
}
export const getSubscriberByNameOrMobileNumber = createAsyncThunk('subscriber/getSubscriberByNameOrMobileNumber', async(personNameOrMobileNumber: string) => {
    const response = await subscriberSearch(personNameOrMobileNumber);
    const responseData = response.lifestreamResponse
    return responseData ? responseData : []
})

const subscriberSearchSlice = createSlice({
    name: 'subscriber',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getSubscriberByNameOrMobileNumber.pending, (state) => {
            state.isLoading = true;
        });

        builder.addCase(getSubscriberByNameOrMobileNumber.fulfilled, (state, action) => {
            
            state.isLoading = false;
            state.data = action.payload as subscriberSearchData[];
            state.error = undefined;
        });

        builder.addCase(getSubscriberByNameOrMobileNumber.rejected, (state, action) => {
            state.isLoading = false;
            state.data = undefined;
            state.error = action.payload as string;
        });
    },
})


export const subscriberSearchSelector = (state: RootState) => state.subscriberSearchData;

export default subscriberSearchSlice.reducer;