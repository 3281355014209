import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';

const storedPassword = localStorage.getItem('password');

const initialState = {
  password: storedPassword || '', // Initialize with the stored password or an empty string
  isOpen: false,
};

const protectedDataPasswordSlice = createSlice({
    name: 'protectedDataPassword',
    initialState,
    reducers: {
        setPassword :(state, action) =>{
            state.password = action.payload;
            localStorage.setItem('password', action.payload);
        },

        initializePassword: (state)=>{
            if(storedPassword){
                state.password = storedPassword;
            };
        },

        clearPassword: (state) => {
            state.password = '';
            localStorage.removeItem('password');
        },

        openModalPopUp: (state) =>{
            state.isOpen = true;
        },
        
        closeModalPopUp: (state) =>{
            state.isOpen = false;
        },

        
    }

});

export const {
  setPassword,
  initializePassword,
  clearPassword,
  openModalPopUp,
  closeModalPopUp
} = protectedDataPasswordSlice.actions;

export const protectedDataPasswordSelector = (state: RootState) => state.protectedDataPassword;

export default protectedDataPasswordSlice.reducer;
