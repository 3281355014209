import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CONSTANTS } from '../constants/constants';
import { RootState } from './store';

export interface MenuData {
  id: string;
  name: string;
  type: string;
  childMenu: ChildMenu[] | [];
  icon: string;
}

export interface ChildMenu {
  id: string;
  name: string;
  type: string;
}
export interface SidebarState {
  isToggleSidebar: boolean,
  breadcrumb: string;
}

const initialState: SidebarState = {
  isToggleSidebar: true,
  breadcrumb: CONSTANTS.SIDEBAR.BREADCRUMB.DASHBOARD
};

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setBreadcrumb: (state, action: PayloadAction<string>) => {
      state.breadcrumb = action.payload;
    },
    setToggleSidebar: (state) => {
      state.isToggleSidebar = !state.isToggleSidebar;
    },
  }
});

export const {
  setBreadcrumb,
  setToggleSidebar,
} = sidebarSlice.actions;

export const sidebarSelector = (state: RootState) => state.sidebar;

export default sidebarSlice.reducer;