import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import './App.css';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { Header } from './container/Header';
import { Sidebar } from './container/Sidebar';
import { Dashboard } from './pages/Dashboard/Dashboard';
import { Inspector } from './pages/Inspector/Inspector';
import { EnrollmentDetail } from './pages/Enrollmentdetails/EnrollmentDetail';
import { Breadcrumb } from './container/Breadcrumb';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { MsalGroup } from './container/MsalGroup';
import { UserProfile } from './pages/UserProfile/UserProfile';
import { useAppSelector } from './redux/hooks';
import { sidebarSelector } from './redux/sidebarSlice';
import { EmergencyNumbers, FirstAidItems, ReferenceData, Subscriptions } from './pages/MasterData';
import { SubscriptionData } from './pages/SubscriberData';
import { LocationTracker } from './container/LocationTracker/LocationTracker';
import { SubscriberDataErrors } from './pages/SubscriberDataErrors/SubscriberDataErrors';

// import { Subscription } from './pages/SubscriberData';

interface Props { }

export const App: React.FC<Props> = () => {
  const sidebar = useAppSelector(sidebarSelector);
  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      <MsalGroup>
        <div className={`nice-admin ${!sidebar.isToggleSidebar ? 'toggle-sidebar' : ''}`}>
          <Router>
            <Header />
            <Sidebar />
            <main id="main" className="main">
              <Breadcrumb />
              <Routes>
                <Route path="/" element={<Navigate to="/dashboard" replace />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/promocodes/:id" element={<EnrollmentDetail />} />
                <Route path="/inspector" element={<Inspector />} />
                <Route path="/master-data/*">
                  <Route path="emergency-numbers" element={<EmergencyNumbers />} />
                  <Route path="subscriptions" element={<Subscriptions />} />
                  <Route path="reference-data" element={<ReferenceData />} />
                  <Route path="first-aid-items" element={<FirstAidItems />} />
                </Route>
                <Route path="/user-profile" element={<UserProfile />} />
                
                <Route path="/subscriber-data">
                  {/* <Route path="" element={<Subscription />} /> */}
                  <Route path="" element={<SubscriptionData />} />
                </Route>
                <Route path="/location-tracking" element={<LocationTracker />} />
                <Route path="/error" element={<SubscriberDataErrors />} />
              </Routes>
            </main>
          </Router>
        </div>
      </MsalGroup>
    </MsalAuthenticationTemplate>
  );
};

export default App;
