/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import './TimeFilter.styles.css';
import { TimeFilterProps } from './TimeFilter.props';

export const TimeFilter: React.FC<TimeFilterProps> = ({
  onToggleValue,
  onClearValue,
  isClear = false,
}) => {
  const ref1m = useRef<any>(null);
  const ref30m = useRef<any>(null);
  const ref60m = useRef<any>(null);
  const ref720m = useRef<any>(null);

  useEffect(() => {
    handleToggle(0);
  }, [isClear]);

  const handleToggle = (mins: number) => {
    mins === 1 ? (ref1m.current.className = 'active') : (ref1m.current.className = '');
    mins === 30 ? (ref30m.current.className = 'active') : (ref30m.current.className = '');
    mins === 60 ? (ref60m.current.className = 'active') : (ref60m.current.className = '');
    mins === 720 ? (ref720m.current.className = 'active') : (ref720m.current.className = '');
    mins !== 0 && onToggleValue(mins);
  };

  const onClear = () => {
    if (
      ref1m.current.className !== 'active' &&
      ref30m.current.className !== 'active' &&
      ref60m.current.className !== 'active' &&
      ref720m.current.className !== 'active'
    )
      return;
    onClearValue();
    handleToggle(0);
  };

  return (
    <div className="time-filter">
      <div className="filter-button">
        <button onClick={onClear}>Clear</button>
        <button ref={ref1m} onClick={() => handleToggle(1)}>
          1m
        </button>
        <button ref={ref30m} onClick={() => handleToggle(30)}>
          30m
        </button>
        <button ref={ref60m} onClick={() => handleToggle(60)}>
          1h
        </button>
        <button ref={ref720m} onClick={() => handleToggle(720)}>
          12h
        </button>
      </div>
    </div>
  );
};
