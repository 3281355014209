import React, { ReactNode, useState } from 'react';
import { Colors } from '../../configs/colors';
import { Text } from '../Text';
import './Accordion.styles.css';

interface AccordionProps {
  title: string;
  children?: ReactNode;
  ContainerListStyle?: React.CSSProperties;
  ContainerTextStyle?: React.CSSProperties;
  featureCode?: string;
  icon?: string;
}

export const Accordion: React.FC<AccordionProps> = ({ title = '', children, ContainerListStyle, ContainerTextStyle, featureCode, icon }) => {
  const [show, setShow] = useState(false);
  const toggleItems = () => {
    setShow(!show);
  };

  return (
    <div className="accordion-container">
      <div className="accordion-list" style={ContainerListStyle}>
        <div className="tile-list" onClick={toggleItems}>
          {
            ContainerTextStyle ? (
              <>
                <div className='pr-4'>
                  <img height={20} width={20} alt='icon_image' src={icon} />
                </div>
                <div className='row justify-content-between p-2 w-100'>
                  <Text color={Colors.NeutralBlack} fontSize={14} fontWeight="700">
                    {title}
                  </Text>
                  <div className='row mr-4'>
                    <div className='pr-4'>
                      <Text fontWeight="400" fontSize={15} color={Colors.NeutralGrey2}>
                        {'Feature Code:'}
                      </Text>
                    </div>
                    <Text color={Colors.LSBlack} fontSize={14} fontWeight="700">
                      {featureCode}
                    </Text>
                  </div>
                </div>
              </>
            )
              :
              (<Text color={Colors.LSNavy} fontSize={16} fontWeight="700">
                {title}
              </Text>)
          }
          <div className="chevron-container">
            <i className={`bi bi-chevron-${show ? 'up' : 'down'}`}></i>
          </div>
        </div>
        <div className="accordion-content">{show && children}</div>
      </div>
    </div >
  );
};
