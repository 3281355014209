import DefaultImageProfile from '../assets/images/no-profile-picture.svg';
import Spinner from '../assets/images/spinner.gif';
import Close from '../assets/images/x-active.svg';
import FirstAidTips from '../assets/images/first-aid-tips.svg';
import Logo from '../assets/images/logo.svg';
import BlackMarker from '../assets/images/marker.svg'
import GreenMarker from '../assets/images/greenMarker.svg'
import RedMarker from '../assets/images/redMarker.svg'
export const images = {
  DefaultImageProfile,
  Spinner,
  Close,
  FirstAidTips,
  Logo,
  BlackMarker,
  GreenMarker,
  RedMarker
}
