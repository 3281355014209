import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getEmergencyNumbers } from '../api/emergencyNumbers';
import { emergencyNumbersData } from '../utils/dataPreparationHelper';
import { RootState } from './store';

export interface EmergencyNumbers {
  country: string;
  emergencyNumbers: EmergencyNumbersData[];
  primaryNumber: string;
}

export interface EmergencyNumbersData {
  description: string;
  image?: string;
  name: string;
  phoneNumber: string;
}
export interface EmergencyNumbersObjectState {
  data?: EmergencyNumbers[];
  isLoading: boolean;
  error?: string;
}

const initialState: EmergencyNumbersObjectState = {
  isLoading: false,
  data: undefined,
  error: undefined,
}

export const emergencyNumbers = createAsyncThunk('emergencyNumbers/ByPhone', async () => {
  const response = await getEmergencyNumbers();
  return response.numbers ? response.numbers.map((res: any) => {
    return {
      country: res.country,
      emergencyNumbers: emergencyNumbersData(res.emergencyNumbers),
      primaryNumber: res.primaryNumber
    }
  }) : [];
})

const emergencyNumbersSlice = createSlice({
  name: 'emergencyNumbers',
  initialState,
  reducers: {
    setEmergencyNumbersData: (state, action: PayloadAction<EmergencyNumbers[]>) => {
      state.isLoading = false;
      state.data = action.payload;
      state.error = undefined;
    },
    setEmergencyNumbersClear: (state) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = undefined;
    },

  },
  extraReducers: (builder) => {
    builder.addCase(emergencyNumbers.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(emergencyNumbers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload as EmergencyNumbers[];
      state.error = undefined;
    });

    builder.addCase(emergencyNumbers.rejected, (state, action) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = action.payload as string;
    });
  },
});

export const {
  setEmergencyNumbersData,
  setEmergencyNumbersClear,
} = emergencyNumbersSlice.actions;

export const emergencyNumbersSelector = (state: RootState) => state.emergencyNumbers;

export default emergencyNumbersSlice.reducer;
