import { CONSTANTS } from '../constants/constants';
import { HttpMethod, httpClient } from './httpClient';


export const getEmergencyNumbers = (): Promise<any> => {
  return httpClient(`/emergencynumbers/numbers?countryCodes=AU`, HttpMethod.Get, null, CONSTANTS.SERVICE_TYPES.EMERGENCY_NUMBERS);
};

export const getFirstAidTips = (): Promise<any> => {
  return httpClient(`/emergencynumbers/firstaidtips/AU`, HttpMethod.Get, null, CONSTANTS.SERVICE_TYPES.EMERGENCY_NUMBERS);
};


export const getFirstAidTipsBySeedData = (): Promise<any> => {
  return httpClient(`/emergencynumbers/firstaidtips/AU/seed-data`, HttpMethod.Post, null, CONSTANTS.SERVICE_TYPES.EMERGENCY_NUMBERS);
};

export const getReferenceData = (): Promise<any> => {
  return httpClient('/emergencynumbers/referencedata', HttpMethod.Get, null, CONSTANTS.SERVICE_TYPES.EMERGENCY_NUMBERS);
};