import React from 'react';
import { Colors } from '../../configs/colors';
import { CONSTANTS } from '../../constants/constants';
import { images } from '../../constants/images';
import { SubscriberDetailsObject } from '../../redux/subscriberDetailsSlice';
import { InputLabel } from '../InputLabel';
import { Text } from '../Text';
import './SubscriberPopup.styles.css';

interface SubscriberPopupProps {
  subscriberDetail?: SubscriberDetailsObject;
  onClose: (value: boolean) => void;
}

export const SubscriberPopup: React.FC<SubscriberPopupProps> = ({ subscriberDetail, onClose }) => {
  return (
    <div className="subscriber-popup">
      <div className="popup-content">
        <div className="popup-title">
          <div className="title">Subscriber Detail</div>
          <div className="close">
            <img width="24px" src={images.Close} onClick={() => onClose(false)} alt="" />
          </div>
        </div>
        <div className="profile-header">
          <div className="profile-header-left">
            <img
              style={{ borderRadius: '50%' }}
              width="100px"
              height="100px"
              src={subscriberDetail?.subscriberData.profileImage || images.DefaultImageProfile}
              onClick={() => onClose(false)}
              alt=""
            />
          </div>
          <div className="profile-header-right">
            <Text fontSize={18} margin="0px" fontWeight="600" color={Colors.NeutralGrey1}>
              {subscriberDetail?.subscriberData.fullName}
              {subscriberDetail?.subscriberData.age && (
                <span>, {subscriberDetail?.subscriberData.age}</span>
              )}
            </Text>
            <Text fontSize={15} margin="2px 0px" fontWeight="400" color={Colors.NeutralGrey1}>
              {subscriberDetail?.subscriberData.address}
            </Text>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <InputLabel
              type="text"
              disabledInput={true}
              label={CONSTANTS.CALLER_OVERVIEW.LABEL.SEX}
              value={subscriberDetail?.subscriberData.sex || 'N/A'}
              widthInput="calc(100% - 116px)"
              widthInputLabel="100px"
            />
            <InputLabel
              type="text"
              disabledInput={true}
              label={CONSTANTS.CALLER_OVERVIEW.LABEL.ETHNICITY}
              value={subscriberDetail?.subscriberData.ethnicity || 'N/A'}
              widthInput="calc(100% - 116px)"
              widthInputLabel="100px"
            />
            <InputLabel
              type="text"
              disabledInput={true}
              label={CONSTANTS.CALLER_OVERVIEW.LABEL.HEIGHT}
              value={subscriberDetail?.subscriberData.height || 'N/A'}
              widthInput="calc(100% - 116px)"
              widthInputLabel="100px"
            />
            <InputLabel
              type="text"
              disabledInput={true}
              label={CONSTANTS.CALLER_OVERVIEW.LABEL.WEIGHT}
              value={subscriberDetail?.subscriberData.weight || 'N/A'}
              widthInput="calc(100% - 116px)"
              widthInputLabel="100px"
            />
            <InputLabel
              type="text"
              disabledInput={true}
              label={CONSTANTS.CALLER_OVERVIEW.LABEL.HAIR_COLOR}
              value={subscriberDetail?.subscriberData.hairColor || 'N/A'}
              widthInput="calc(100% - 116px)"
              widthInputLabel="100px"
            />
          </div>
          <div className="col-md-6">
            <InputLabel
              type="text"
              disabledInput={true}
              label={CONSTANTS.CALLER_DETAILS.LABEL.MOBILE}
              value={subscriberDetail?.subscriberContactInfo.mobile || 'N/A'}
              widthInput="calc(100% - 106px)"
              widthInputLabel="90px"
              textColor={true}
            />
            <InputLabel
              type="text"
              disabledInput={true}
              label={CONSTANTS.CALLER_DETAILS.LABEL.HOME}
              value={subscriberDetail?.subscriberContactInfo.home || 'N/A'}
              textColor={true}
              widthInput="calc(100% - 106px)"
              widthInputLabel="90px"
            />
            <InputLabel
              type="text"
              disabledInput={true}
              label={CONSTANTS.CALLER_DETAILS.LABEL.WORK}
              value={subscriberDetail?.subscriberContactInfo.work || 'N/A'}
              widthInput="calc(100% - 106px)"
              widthInputLabel="90px"
              textColor={true}
            />
            <InputLabel
              type="text"
              disabledInput={true}
              label={CONSTANTS.CALLER_DETAILS.LABEL.EMAIL}
              value={subscriberDetail?.subscriberData.email || 'N/A'}
              widthInput="calc(100% - 106px)"
              widthInputLabel="90px"
              textColor={true}
            />
            <InputLabel
              type="text"
              disabledInput={true}
              label={CONSTANTS.CALLER_OVERVIEW.LABEL.LIVING_STATUS}
              value={subscriberDetail?.subscriberData.livingStatus || 'N/A'}
              widthInput="calc(100% - 116px)"
              widthInputLabel="100px"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
