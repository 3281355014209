import React from 'react';
import { images } from '../../constants/images';
import { FirstAidItemsRes } from '../../redux/firstAidItemsSlice';
import './FirstAidItemsPopup.styles.css';

interface FirstAidItemsPopupProps {
  firstAidItemsPopupData?: FirstAidItemsRes;
  onClose: (value: boolean) => void;
}

export const FirstAidItemsPopup: React.FC<FirstAidItemsPopupProps> = ({
  firstAidItemsPopupData,
  onClose,
}) => {
  return (
    <div className="event-popup">
      <div className="event-popup-content">
        <div className="popup-title">
          <div className="title">
            {firstAidItemsPopupData?.displayOrder}. {firstAidItemsPopupData?.displayText}
          </div>
          <div className="close">
            <img width="24px" src={images.Close} onClick={() => onClose(false)} alt="" />
          </div>
        </div>
        <div className="detail-data">
          <pre>{JSON.stringify(firstAidItemsPopupData?.components, null, 2)}</pre>
        </div>
      </div>
    </div>
  );
};
