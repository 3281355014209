import { useMsal } from '@azure/msal-react';
import React, { useState, useEffect } from 'react';
import appConfig from '../configs/appConfig.json';
interface MsalGroupProps {
  children: React.ReactNode;
}

export const MsalGroup: React.FC<MsalGroupProps> = ({ children }) => {
  const { instance, accounts } = useMsal();
  const [authorized, setAuthorized] = useState<boolean | undefined>(undefined);

  const handleSignOut = async () => {
    try {
      localStorage.clear();
      const logingIdClaims = accounts[0].idTokenClaims as any;
      await instance.logoutRedirect({
        authority: appConfig.logoutRequest.authority,
        account: accounts[0],
        postLogoutRedirectUri: appConfig.logoutRequest.postLogoutRedirectUri,
        logoutHint: logingIdClaims.login_hint,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getAuthorized = async () => {
    let authorized;
    const response: any = await instance.acquireTokenSilent({
      ...appConfig.loginRequest,
      account: accounts[0],
    });

    if (!response || !response.idTokenClaims || !response.idTokenClaims.groups) {
      setAuthorized(false);
    } else {
      appConfig.Groups.groups.map((group: string) => {
        if (response.idTokenClaims.groups.includes(group)) {
          authorized = true;
        } else {
          authorized = false;
        }
        return null;
      });
    }
    setAuthorized(authorized);
  };

  useEffect(() => {
    getAuthorized();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance]);

  return (
    <>
      {(authorized && <>{children}</>) ||
        (authorized === false && (
          <div className="container">
            <section className="section error-404 min-vh-100 d-flex flex-column align-items-center justify-content-center">
              <h1>404</h1>
              <h2>You don't have the permission to access this page</h2>
              <div className="btn" onClick={handleSignOut}>
                Back to login page
              </div>
              <img src="assets/img/not-found.svg" className="img-fluid py-5" alt="Page Not Found" />
            </section>
          </div>
        ))}
    </>
  );
};
