/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Sidebar.css';
import { CONSTANTS } from '../constants/constants';
import { useAppDispatch } from '../redux/hooks';
import { clearPassword, openModalPopUp } from '../redux/protectedDataPasswordSlice';
import { setClearInspectorEventData } from '../redux/inspectorSlice';
import { ChildMenu, MenuData, setBreadcrumb } from '../redux/sidebarSlice';
import { setSubscriberDetailsClear } from '../redux/subscriberDetailsSlice';
import { menuData } from '../utils/dataPreparationHelper';
import { removeActiveMenu } from '../utils/commonFunctions';
import { setSubscriptionClear } from '../redux/subscriptionSlice';

interface SidebarProps { }

export const Sidebar: React.FC<SidebarProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {

    if (location.pathname.includes('/user-profile')) {
      removeActiveMenu(menuData);
      dispatch(setBreadcrumb(CONSTANTS.SIDEBAR.BREADCRUMB.USER_PROFILE));
    }
    if (location.pathname.includes('/master-data/emergency-numbers')) {
      dispatch(setBreadcrumb(CONSTANTS.SIDEBAR.BREADCRUMB.EMERGENCY_NUMBERS));
    }
    if (location.pathname.includes('/master-data/reference-data')) {
      dispatch(setBreadcrumb(CONSTANTS.SIDEBAR.BREADCRUMB.REFERENCE_DATA));
    }
    if (location.pathname.includes('/master-data/first-aid-items')) {
      dispatch(setBreadcrumb(CONSTANTS.SIDEBAR.BREADCRUMB.FIRST_AID_ITEMS));
    }
    if (location.pathname.includes('/master-data/subscriptions')) {
      dispatch(setBreadcrumb(CONSTANTS.SIDEBAR.BREADCRUMB.SUBSCRIPTIONS));
    }
    if (location.pathname.includes('/subscriber-data/subscription')) {
      dispatch(setBreadcrumb(CONSTANTS.SIDEBAR.BREADCRUMB.SUBSCRIBER_DATA.SUBSCRIPTION));
    }
  }, [location.pathname]);

  const toggleParentMenu = (value: MenuData) => {

    switch (value.type) {
      case CONSTANTS.SIDEBAR.TYPE.DASHBOARD:
        navigate('/dashboard');
        dispatch(setBreadcrumb(CONSTANTS.SIDEBAR.BREADCRUMB.DASHBOARD));
        dispatch(setClearInspectorEventData());
        dispatch(setSubscriberDetailsClear());
        break;
      case CONSTANTS.SIDEBAR.TYPE.INSPECTOR:
        navigate('/inspector');
        dispatch(setBreadcrumb(CONSTANTS.SIDEBAR.BREADCRUMB.INSPECTOR));
        break;
      case CONSTANTS.SIDEBAR.TYPE.SUBSCRIBER_DATA:
        navigate('/subscriber-data');
        break;
      case CONSTANTS.SIDEBAR.TYPE.LOCATION_TRACKING:
        navigate('/location-tracking');
        dispatch(setBreadcrumb(CONSTANTS.SIDEBAR.BREADCRUMB.LOCATION_TRACKING));
        break;

      default:
        break;
    }
    // When clicking a menu have not the submenu
    const elm = document.getElementById(value.id);
    menuData.map((item) => {

      if (elm?.children[1].children.length === 0) {
        if (value.id === item.id) {
          elm?.children[0].classList.remove('collapsed');
        } else {
          const elms = document.getElementById(item.id);
          if (!elms?.children[0].className.includes('collapsed')) {
            elms?.children[0].classList.add('collapsed');
          }
          elms?.children[1].classList.remove('show');
        }
        // remove submenu active
        item.childMenu.map((chi) => {
          const elms = document.getElementById(chi.id);
          elms?.classList.remove('active');
          return null;
        });
      }
      // When clicking a menu have the submenu
      if (elm && elm?.children[1].children.length > 0) {
        if (value.id === item.id) {
          if (elm?.children[0].className.includes('collapsed')) {
            elm?.children[0].classList.remove('collapsed');
            elm?.children[1].classList.add('show');
          } else {
            elm?.children[0].classList.add('collapsed');
            elm?.children[1].classList.remove('show');
          }
        } else {
          const elms = document.getElementById(item.id);
          if (
            !elms?.children[0].className.includes('collapsed') &&
            elms &&
            elms?.children[1].children.length > 0
          ) {
            elms?.children[0].classList.add('collapsed');
            elms?.children[1].classList.remove('show');
          }
        }
      }

      return null;
    });
  };

  const toggleSubMenu = (value: MenuData, child: ChildMenu) => {
    dispatch(setClearInspectorEventData());
    dispatch(setSubscriberDetailsClear());
    if (child.type !== CONSTANTS.SIDEBAR.TYPE.SUBSCRIBER_DATA_TYPE.SUBSCRIPTION) {
      dispatch(setSubscriptionClear())
    }
    switch (child.type) {
      case CONSTANTS.SIDEBAR.TYPE.MASTER_DATA_TYPE.EMERGENCY_NUMBERS:
        navigate('/master-data/emergency-numbers');
        dispatch(setBreadcrumb(CONSTANTS.SIDEBAR.BREADCRUMB.EMERGENCY_NUMBERS));
        break;
      case CONSTANTS.SIDEBAR.TYPE.MASTER_DATA_TYPE.REFERENCE_DATA:
        navigate('/master-data/reference-data');
        dispatch(setBreadcrumb(CONSTANTS.SIDEBAR.BREADCRUMB.REFERENCE_DATA));
        break;
      case CONSTANTS.SIDEBAR.TYPE.MASTER_DATA_TYPE.SUBSCRIPTIONS:
        navigate('/master-data/subscriptions');
        dispatch(setBreadcrumb(CONSTANTS.SIDEBAR.BREADCRUMB.SUBSCRIPTIONS));
        break;
      case CONSTANTS.SIDEBAR.TYPE.MASTER_DATA_TYPE.FIRST_AID_ITEMS:
        navigate('/master-data/first-aid-items');
        dispatch(setBreadcrumb(CONSTANTS.SIDEBAR.BREADCRUMB.FIRST_AID_ITEMS));
        break;
      case CONSTANTS.SIDEBAR.TYPE.SUBSCRIBER_DATA_TYPE.SUBSCRIPTION:
        navigate('/subscriber-data/subscription');
        dispatch(setBreadcrumb(CONSTANTS.SIDEBAR.BREADCRUMB.SUBSCRIBER_DATA.SUBSCRIPTION));
        break;

      default:
        break;
    }

    const chElement = document.getElementById(child.id);
    // Add 'active' for submenu and remove menu acting
    menuData.map((item) => {
      if (item.id !== value.id) {
        const elms = document.getElementById(item.id);
        if (!elms?.children[0].className.includes('collapsed')) {
          elms?.children[0].classList.add('collapsed');
        }
      }
      item.childMenu.map((chi) => {
        if (chi.id === child.id) {
          chElement?.classList.add('active');
        } else {
          const elms = document.getElementById(chi.id);
          elms?.classList.remove('active');
        }
        return null;
      });
      return null;
    });
  };

  return (
    <div>
      <div>
        <aside id="sidebar" className="sidebar">
          <ul className="sidebar-nav" id="sidebar-nav">
            {menuData.map((item) => (
              <li id={item.id} key={item.id} className="nav-item">
                <div className={`nav-link collapsed`} onClick={() => toggleParentMenu(item)}>
                  <i className={item.icon}></i>
                  <span>{item.name}</span>
                  {item.childMenu.length > 0 && <i className="bi bi-chevron-down ms-auto"></i>}
                </div>
                <ul className={`nav-content collapse`}>
                  {item.childMenu.length > 0 &&
                    item.childMenu.map((child) => (
                      <li key={child.id} onClick={() => toggleSubMenu(item, child)}>
                        <div id={child.id} className="nav-content-item">
                          <i className="bi bi-circle"></i>
                          <span>{child.name}</span>
                        </div>
                      </li>
                    ))}
                </ul>
              </li>
            ))}
          </ul>
        </aside>
      </div>
    </div>
  );
};
