import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getCouponList } from '../api/subscriptions';
import { RootState } from './store';

export interface CouponListObjectState {
  data?: CouponListData[] | null;
  isLoading: boolean;
  error?: string | null;
}

export interface CouponListData {
  applies_to: [],
  duration: string,
  id: string,
  name: string,
  percent_off: number,
  duration_in_months: number
}

const initialState: CouponListObjectState = {
  isLoading: false,
  data: null,
  error: null,
}

export const getCouponsListByCode = createAsyncThunk('couponsList/getCouponList', async() => {    
  const response = await getCouponList();  
  const dataResponse = response.lifestreamResponse.data;  
  return dataResponse ? dataResponse : []
})

const couponsListSlice = createSlice({
  name: 'couponsList',
  initialState,
  reducers: {
    setCouponsListData: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    setCouponsListClear: (state) => {
      state.isLoading = false;
      state.data = null;
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getCouponsListByCode.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getCouponsListByCode.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload as any;
      state.error = undefined;
    });

    builder.addCase(getCouponsListByCode.rejected, (state, action) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = action.payload as string;
    });
  },
});

export const {
  setCouponsListData,
  setCouponsListClear,
} = couponsListSlice.actions;

export const couponsListSelector = (state: RootState) => state.couponsList;

export default couponsListSlice.reducer;
