/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Accordion } from '../../components/Accordion/Accordion';
import { Text } from '../../components/Text';
import { Colors } from '../../configs/colors';
import { images } from '../../constants/images';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getReference, referenceSelector } from '../../redux/referenceSlice';
import { ModalConfirm } from '../../components/ModalConfirm/ModalConfirm';
import { useNavigate } from 'react-router-dom';
import { setBreadcrumb } from '../../redux/sidebarSlice';
import { CONSTANTS } from '../../constants/constants';
import appConfig from '../../configs/appConfig.json';
import { closeModalPopUp, initializePassword, openModalPopUp, protectedDataPasswordSelector, setPassword } from '../../redux/protectedDataPasswordSlice';

interface ReferenceDataProps { }

export const ReferenceData: React.FC<ReferenceDataProps> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const referenceRes = useAppSelector(referenceSelector);
  const { password, isOpen } = useAppSelector(protectedDataPasswordSelector);
  const [passwordText, setPasswordText] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const renderContent = (data: Array<string | any>, isArray?: boolean) => {
    return data.map((item, index) => {
      return (
        <div className="accordion-content-item" key={index}>
          <div className="text-content">
            <Text fontSize={14} fontWeight="700" color={Colors.LSDarkTeal}>
              {index + 1}.
            </Text>
            <Text fontSize={14} color={Colors.LSBlack}>
              {isArray ? item.name : item}
            </Text>
          </div>
          <div className="icon">
            <i className="bi bi-pencil-square"></i>
            <i className="bi bi-trash"></i>
          </div>
        </div>
      );
    });
  };

  const onClickOk = () => {
    if (passwordText !== '') {
      setErrorMessage('')
      console.log(atob(appConfig?.Protected_Data_Password?.password))
      if (atob(appConfig?.Protected_Data_Password?.password) === passwordText) {
        dispatch(closeModalPopUp());
        navigate('/master-data/reference-data');
        dispatch(setPassword(passwordText));
        dispatch(setBreadcrumb(CONSTANTS.SIDEBAR.BREADCRUMB.REFERENCE_DATA));
      } else {
        navigate('/error');
        dispatch(closeModalPopUp());
        dispatch(setBreadcrumb(CONSTANTS.SIDEBAR.BREADCRUMB.ERROR));
      }
    } else {
      setErrorMessage('Password required');
    }
  };

  useEffect(() => {
    if (!password) {
      dispatch(openModalPopUp());
    }
    else {
      if (atob(appConfig?.Protected_Data_Password?.password) === password) {
        dispatch(closeModalPopUp());
        navigate('/master-data/reference-data');
        dispatch(setBreadcrumb(CONSTANTS.SIDEBAR.BREADCRUMB.REFERENCE_DATA));
      }
      else {
        setErrorMessage('Password required');
      }
    }
  }, [password])

  useEffect(() => {
    dispatch(getReference());
    dispatch(initializePassword());
  }, []);

  return (
    <>
      {
        isOpen ? (<ModalConfirm
          onConfirm={() => onClickOk()}
          title="Password"
          content="This page is access-restricted by password. If you do not possess the password, kindly contact the Tech team to request access"
          onClose={() => {
            navigate('/error');
            dispatch(closeModalPopUp());
            dispatch(setBreadcrumb(CONSTANTS.SIDEBAR.BREADCRUMB.ERROR));

          }} confirmText="OK" >
          <div className='mt-2'>
            <label htmlFor="password">Password</label>
            <input className='form-control' type='password' onChange={(e) => setPasswordText(e.target.value)} placeholder='Enter Your Password' />
            {errorMessage !== '' ? (<p style={{ color: 'red' }}>Required</p>) : ('')}
          </div>
        </ModalConfirm>) : (
          <div className="reference-data-container">
            <div className="card card-table">
              <div className="card-body">
                {!referenceRes.isLoading ? (
                  referenceRes.data &&
                  referenceRes.data.length > 0 &&
                  referenceRes.data.map((item) => (
                    <Accordion key={item.id} title={item.title}>
                      {renderContent(item.content, item.title === 'Medical Conditions')}
                    </Accordion>
                  ))
                ) : (
                  <div className="d-flex justify-content-center">
                    <div className="loader-image">
                      <img src={images.Spinner} alt="" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

        )
      }
    </>
  );
};
