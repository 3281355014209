import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import { getSubscriberById } from '../api/subscribers';
import { getDateTimeDifference } from '../utils/dateTimeHelper';

export interface DataMedicalInformation {
  doctor: string;
  doctorMobile: string;
  bloodType: string;
  allergies: string
  criticalMedications: string
  otherInformation: string;
  conditions: Array<Condition>;
  markedMedicalConditions?: string[]
}

export interface Condition {
  checked: boolean;
  name: string;
  id: string;
}

export interface UserDetails {
  address?: string;
  ethnicity?: string;
  fullName?: string;
  hairColor?: string
  height?: string;
  home?: string;
  livingStatus?: string;
  mobile?: string;
  received?: string;
  time?: string;
  weight?: string;
  work?: string;
  latitude?: string;
  longitude?: string;
  sex?: string;
  email?: string;
  profileImage?: string;
  age?: string;
  id?: string;
}

export enum SortColumn {
  ContactName = 'contactname',
  Group = 'group',
  Relationship = 'relationship',
  LastFix = 'lastfix',
  Distance = 'distance'
}

export interface SubscriberContactInfoObject {
  mobile: string;
  home: string;
  work: string;
  video: string;
  chat: string;
}

export interface TelephoneNumber {
  number: string;
  countryCode: string;
}

export interface EmergencyContactObject {
  id: string;
  subScriberID: string;
  contactName: string;
  group: string;
  relationship: string;
  contact: string;
  currentLocation: string;
  lastFix: string;
  distance: string;
  url: string;
  type: string;
  home: string;
  work: string;
  mobile: TelephoneNumber;
}

export interface SubscriberDetailsObject {
  subscriberContactInfo: SubscriberContactInfoObject;
  emergencyContactList: Array<EmergencyContactObject>;
  subscriberData: UserDetails;
  medicalData: DataMedicalInformation;
  watchmeConfig?: WatchMeConfig,
  isDeleted?: boolean;
}

export interface SubscriberDetailsObjectState {
  data?: SubscriberDetailsObject;
  isLoading: boolean;
  error?: string;
}

export interface WatchMeConfig {
  primaryContactId: string;
  secondaryContactId: string;
  useEmergencyService: boolean;
}

const initialState: SubscriberDetailsObjectState = {
  data: undefined,
  isLoading: false,
  error: undefined
};

export const subscriberDetailsById = createAsyncThunk('subscriberDetails/ById', async (subscriberId: string) => {
  const response = await getSubscriberById(subscriberId);
  const data = response.lifestreamResponse.data;
  return {
    subscriberContactInfo: {
      mobile: `${data.account.mobileNumber.countryCode}${data.account.mobileNumber.number}`,
      home: `${data.additionalDetails?.homeNumber?.countryCode || ''}${data.additionalDetails?.homeNumber?.number || ''}`,
      work: `${data.additionalDetails?.workNumber?.countryCode || ''}${data.additionalDetails?.workNumber?.number || ''}`,
      video: `${data.account.mobileNumber.countryCode}${data.account.mobileNumber.number}`,
      chat: `${data.account.mobileNumber.countryCode}${data.account.mobileNumber.number}`,
    },
    emergencyContactList: data.emergencyContacts?.map((contact: any) => ({
      id: contact.id,
      subScriberID: subscriberId,
      contactName: `${contact.firstName} ${contact.lastName}`,
      group: 'EC',
      relationship: contact.relationship,
      contact: `${contact.mobileNumber.countryCode}${contact.mobileNumber.number}`,
      currentLocation: contact.homeAddress,
      lastFix: '',
      distance: '',
      url: '',
      type: contact.contactType,
      home: `${contact.homeNumber?.countryCode || ''}${contact.homeNumber?.number || ''}`,
      work: `${contact.workNumber?.countryCode || ''}${contact.workNumber?.number || ''}`,
      mobile: contact.mobileNumber
    })) || [],
    subscriberData: {
      id: data.account.subscriberId,
      address: data.account.homeAddress,
      email: data.account.emailAddress,
      ethnicity: data.additionalDetails?.ethnicity,
      fullName: `${data.account.firstName || 'N/A'} ${data.account.lastName || ''}`,
      hairColor: data.additionalDetails?.hairColor,
      height: data.additionalDetails?.heightInCm,
      weight: data.additionalDetails?.weightInKg,
      home: `${data.additionalDetails?.homeNumber?.countryCode ?? ''}${data.additionalDetails?.homeNumber?.number ?? ''}`,
      livingStatus: data.subscriberSnapshot?.additionalDetails?.livingStatus,
      mobile: `${data.account.mobileNumber.countryCode}${data.account.mobileNumber.number}`,
      sex: data.additionalDetails?.gender,
      work: `${data.additionalDetails?.workNumber?.countryCode ?? ''}${data.additionalDetails?.workNumber?.number ?? ''}`,
      profileImage: data.profilePhoto?.image ?? '',
      age: getDateTimeDifference(data.account.dateOfBirth).toString()
    },
    medicalData: {
      allergies: data.additionalDetails?.allergyDetails,
      bloodType: data.additionalDetails?.bloodType,
      criticalMedications: data.additionalDetails?.criticalMedications,
      doctor: data.additionalDetails?.doctorsName,
      doctorMobile: `${data.additionalDetails?.doctorsNumber?.countryCode ?? ''}${data.additionalDetails?.doctorsNumber?.number ?? ''}`,
      otherInformation: data.additionalDetails?.otherInformation,
      conditions: [],
      markedMedicalConditions: data.additionalDetails?.medicalConditions
    },
    watchmeConfig: data.watchMeConfig,
    isDeleted: data.account.isDeleted
  };
});

const subscriberDetailsSlice = createSlice({
  name: 'subscriberDetails',
  initialState,
  reducers: {
    setSubscriberDetailsData: (state, action: PayloadAction<SubscriberDetailsObject | undefined>) => {
      state.isLoading = false;
      state.data = action.payload as SubscriberDetailsObject;
      state.error = undefined;
    },
    setSubscriberDetailsClear: (state) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = undefined;
    },
    sortSubscriberContactData: (state, action: PayloadAction<any>) => {
      if (state.data) {
        switch (action.payload.SortBy) {
          case SortColumn.ContactName:
            state.data.emergencyContactList = state.data.emergencyContactList.sort((a, b) => action.payload.IsAscending ? a.contactName.localeCompare(b.contactName) : b.contactName.localeCompare(a.contactName));
            break;

          case SortColumn.Group:
            state.data.emergencyContactList = state.data.emergencyContactList.sort((a, b) => action.payload.IsAscending ? a.group.localeCompare(b.group) : b.group.localeCompare(a.group));
            break;

          case SortColumn.Relationship:
            state.data.emergencyContactList = state.data.emergencyContactList.sort((a, b) => action.payload.IsAscending ? a.relationship.localeCompare(b.relationship) : b.relationship.localeCompare(a.relationship));
            break;

          case SortColumn.Distance:
            state.data.emergencyContactList = state.data.emergencyContactList.sort((a, b) => action.payload.IsAscending ? a.distance.localeCompare(b.distance) : b.distance.localeCompare(a.distance));
            break;

          // TODO : We'll have to change the logic based on the type we get here for dates
          case SortColumn.LastFix:
            state.data.emergencyContactList = state.data.emergencyContactList.sort((a, b) => action.payload.IsAscending ? a.lastFix.localeCompare(b.lastFix) : b.lastFix.localeCompare(a.lastFix));
            break;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(subscriberDetailsById.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(subscriberDetailsById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload as SubscriberDetailsObject;
      state.error = undefined;
    });

    builder.addCase(subscriberDetailsById.rejected, (state, action) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = action.payload as string;
    });
  },
});

export const {
  setSubscriberDetailsData,
  setSubscriberDetailsClear,
  sortSubscriberContactData
} = subscriberDetailsSlice.actions;

export const subscriberDetailsSelector = (state: RootState) => state.subscriberDetails;

export default subscriberDetailsSlice.reducer;
