import React, { useState } from 'react';
import { Colors } from '../../configs/colors';
import { InputField } from '../InputField/InputField';
import { InputFieldVariant } from '../InputField/InputField.props';
import './SearchBar.styles.css';

interface SearchBarProps {
  onSearchPress: (value: string) => void;
  disabled?: boolean;
  label?: string;
  placeholder?: string;
}

export const SearchBar: React.FC<SearchBarProps> = ({
  onSearchPress,
  disabled = false,
  label = 'ENTER SUBSCRIBER ID',
  placeholder = 'Enter subscriber id',
}) => {
  const [searchText, setSearchText] = useState('');
  const [checkFocus, setCheckFocus] = useState<boolean>(false);

  const onKeyPress = (event: any) => {
    if (event.charCode === 10 || event.charCode === 13) {
      event.preventDefault();
      onSearchPress(searchText);
    }
  };

  return (
    <div className={`search-bar-text-wrap ${checkFocus ? 'focused' : ''}`}>
      <InputField
        variant={InputFieldVariant.Labelled}
        width="100%"
        label={label}
        labelColor={Colors.NeutralGrey2}
        type="text"
        alt=""
        placeholder={placeholder}
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        onCheckFocus={(value) => setCheckFocus(value)}
        closeIcon={!!searchText}
        onClearValue={() => setSearchText('')}
        height="50px"
        onKeyPress={onKeyPress}
      />
      <button
        className="button-style"
        onClick={() => onSearchPress(searchText)}
        disabled={disabled || !searchText}
      >
        Search
      </button>
    </div>
  );
};
