import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { deactiveSubscriber } from "../api/subscribers";

export const deactivateAccount = createAsyncThunk('subscriber/deactive', async (subscriberId: string) => {
    const response = await deactiveSubscriber(subscriberId)
    return response
})

const DeactiveAccountSlice = createSlice({
    name: 'deactivate',
    initialState: { data: null },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(deactivateAccount.fulfilled, (state, action) => {
            state.data = action.payload;
        });
    }
})

export const deactiveAccountSelector = (state: RootState) => state.deactiveAccount;

export default DeactiveAccountSlice.reducer;