import React, { ChangeEvent, useEffect, useState } from 'react';
import Map from '../../components/Map/Map';
import './LocationTracker.css'
import { LocationTrackerData } from './LocationTracker.props';
import { LocationList } from '../../constants/rowData';

export const LocationTracker: React.FC = () => {
    const [recordCount, setRecordCount] = useState<number>(0);
    const [mapData, setMapData] = useState<LocationTrackerData[]>([]); // Initialize with an empty array
    const [mapCenter, setMapCenter] = useState<{ lat: number; lng: number }>({ lat: 3.1390, lng: 101.6869 }); // Initialize with default center
    const [mapZoom, setMapZoom] = useState<number>(3); // Initialize with default zoom
    const [lokiIdList, setLokiIdList] = useState<string[]>([]);
    const [showSearchCard, setShowSearchCard] = useState<boolean>(false);

    const handleSearchToggle = () => {
        setShowSearchCard(!showSearchCard);
    };

    const handleInputChange = (index: number, event: ChangeEvent<HTMLInputElement>) => {
        const values = [...lokiIdList];
        values[index] = event.target.value;
        setLokiIdList(values);
    };

    const handleAddField = () => {
        setLokiIdList([...lokiIdList, '']);
    };

    const handleRemoveField = (index: number) => {
        const values = [...lokiIdList];
        values.splice(index, 1);
        setLokiIdList(values);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const data = LocationList;
        setMapData(data);
        setMapZoom(15);
        setMapCenter(data[0]);
        setShowSearchCard(false);
        // Access the values of all input fields in the 'lokiIdList' array
        // console.log('Submitted input values:', lokiIdList.map((field) => field));
    };

    return (
        <div data-testid="location-tracker">
            <form onSubmit={handleSubmit}>
                <div className="search-container">
                    <div data-testid='toggle-button-card' className="card" onClick={handleSearchToggle}>
                        <div className="col-12">
                            <div className="row d-flex aling-items-center p-2">
                                <div className="col-2">
                                    <i className="bi bi-search"></i>
                                </div>
                                <div className="col-8">
                                    <span>Search By Loki Id</span>
                                </div>
                                <div className="col-2">
                                    <i className={`bi bi-chevron-${showSearchCard ? 'up' : 'down'}`} ></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div data-testid='search-card' className='inputContainer'>
                    {showSearchCard && (<div className="card cstm-card p-3">
                        {lokiIdList.length === 0 && (
                            <div key={0}>
                                <div className="row lokiIdList-input">
                                    <div className="col-6 mb-2">
                                        <input
                                            className='form-control rounded-input1'
                                            value={lokiIdList[0]}
                                            onChange={(event) => handleInputChange(0, event)}
                                            placeholder='Loki Id'
                                        />
                                    </div>
                                    <div className="col-6 d-flex add-btn mb-2">
                                        <i className="bi bi-plus-circle-fill" onClick={handleAddField}></i>
                                    </div>
                                </div>
                            </div>

                        )}

                        {lokiIdList.length > 0 && lokiIdList.map((inputValue, index) => (
                            <div key={index}>
                                <div data-testid='lokiIdList' className="row lokiIdList-input">
                                    <div className="col-6 mb-2">
                                        <input
                                            className='form-control rounded-input1'
                                            value={inputValue}
                                            data-testid=''
                                            onChange={(event) => handleInputChange(index, event)}
                                            placeholder='Loki Id'
                                        />
                                    </div>
                                    {index !== lokiIdList.length - 1 && (
                                        <div className="col-6 d-flex remove-btn mb-2">
                                            <i data-testid='remove-button' className="bi-x-circle-fill" onClick={() => handleRemoveField(index)}></i>
                                        </div>
                                    )}
                                    {index === lokiIdList.length - 1 && (
                                        <div className="col-6 d-flex add-btn mb-2" >
                                            <i data-testid='add-button' className="bi bi-plus-circle-fill" onClick={handleAddField}></i>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                        <div className="row mt-2 fetch-last-row">
                            <div className="col-10 p-0">
                                <div className="fetch-last">
                                    <span className='fetch-txt p-0'>Fetch last:</span>
                                    <span>
                                        <input className='form-control rounded-input2'
                                            type="text" value={recordCount} onChange={(e) => setRecordCount(Number(e.target.value))} />
                                    </span>
                                </div>
                            </div>

                            <div className="col-2 p-0 fetchX">
                                <button className='btn navigation-btn' type='submit'> <i data-testid='submit-button-icon' className="bi bi-cursor-fill"></i></button>
                            </div>
                        </div>
                    </div>)

                    }
                </div>
            </form>
            <div>
                <Map mapData={mapData} center={mapCenter} zoom={mapZoom} containerStyle={{ width: '100%', height: '580px' }} />
            </div>
        </div>
    );
}

