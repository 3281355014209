/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TableHeader } from '../../components/TableHeader/TableHeader';
import './EnrollmentDetail.style.css';
import {
  getPromoCodesDetailByCode,
  promoCodesDetailSelector,
} from '../../redux/promoCodesDetailSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { EnrollmentDetailData } from '../Dashboard/Dashboard.props';
import { images } from '../../constants/images';
import { formatDate } from '../../utils/dateTimeHelper';
import { EnrollmentDetailPopup } from '../../components/EnrollmentDetailPopUp/EnrollmentDetailPopup';

interface EnrollmentDetailProps {}

const dataHeader = [
  {
    type: 'Subscriber',
    widthType: '35%',
  },
  {
    type: 'Subscription Name',
    widthType: '15%',
  },
  {
    type: 'Created At',
    widthType: '20%',
  },
  {
    type: 'Stripe Status',
    widthType: '20%',
  },
  {
    type: 'Other Detail',
    widthType: '10%',
  },
];

export const EnrollmentDetail: React.FC<EnrollmentDetailProps> = () => {
  const dispatch = useAppDispatch();
  const enrollmentDetailEventData = useAppSelector(promoCodesDetailSelector);
  const [enrollmentData, setEnrollmentData] = useState<EnrollmentDetailData>();
  const [showOtherDetailPopup, setOtherDetailPopup] = useState<boolean>(false);

  const { id } = useParams();
  useEffect(() => {
    dispatch(getPromoCodesDetailByCode(id || ''));
  }, []);

  const handleDisplayOtherDetail = (data: EnrollmentDetailData) => {
    setEnrollmentData(data);
    setOtherDetailPopup(true);
  };
  return (
    <div className="enrollment-detail">
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
            <div className="col-xxl-4 col-md-12">
              <div className="card info-card sales-card">
                <div className="card-body">
                  <h5 className="card-title">
                    Enrollments for Promocodes <span>| Till Date</span>
                  </h5>

                  <div className="table promocode-table">
                    <TableHeader dataHeader={dataHeader} />
                    <div className="table-content scroll-custom">
                      {(!enrollmentDetailEventData.data ||
                        enrollmentDetailEventData.data.promoCodesByIdData.length === 0) &&
                        !enrollmentDetailEventData.isLoading && (
                          <div className="no-data">No Data Available</div>
                        )}
                      {!enrollmentDetailEventData.isLoading ? (
                        enrollmentDetailEventData.data &&
                        enrollmentDetailEventData.data.promoCodesByIdData.length > 0 &&
                        enrollmentDetailEventData.data.promoCodesByIdData.map(
                          (item: EnrollmentDetailData) => (
                            <div key={item.enrollmentId}>
                              <div className="table-row-content" id={`tr-${item.enrollmentId}`}>
                                <div
                                  className="link-color"
                                  style={{ width: dataHeader[0].widthType }}
                                >
                                  {item.subscriberId}
                                </div>
                                <div style={{ width: dataHeader[1].widthType }}>
                                  {item.subscriptionName}
                                </div>
                                {item.createdAt && (
                                  <div style={{ width: dataHeader[2].widthType }}>
                                    {formatDate(item.createdAt, 'YYYY-MM-DD HH:mm:ss')}
                                  </div>
                                )}
                                <div style={{ width: dataHeader[3].widthType }}>
                                  {item.stripeStatus}
                                </div>
                                <div style={{ width: dataHeader[4].widthType }}>
                                  <a
                                    className="icon"
                                    onClick={() => handleDisplayOtherDetail(item)}
                                  >
                                    <i className="bi bi-three-dots"></i>
                                  </a>
                                </div>
                              </div>
                              {showOtherDetailPopup &&
                                enrollmentData?.enrollmentId === item.enrollmentId && (
                                  <EnrollmentDetailPopup
                                    detailEvent={enrollmentData}
                                    onClose={(value) => setOtherDetailPopup(value)}
                                  />
                                )}
                            </div>
                          )
                        )
                      ) : (
                        <div className="d-flex justify-content-center">
                          <div className="loader-image">
                            <img src={images.Spinner} alt="" />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
