/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { FirstAidItemsPopup } from '../../components/FirstAidItemsPopup/FirstAidItemsPopup';
import { ModalConfirm } from '../../components/ModalConfirm/ModalConfirm';
import { Text } from '../../components/Text';
import { Colors } from '../../configs/colors';
import { images } from '../../constants/images';
import {
  getFirstAidItems,
  firstAidItemsSelector,
  FirstAidItemsRes,
  getFirstAidSeedData,
} from '../../redux/firstAidItemsSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import './MasterData.styles.css';

interface FirstAidItemsProps {}

export const FirstAidItems: React.FC<FirstAidItemsProps> = () => {
  const dispatch = useAppDispatch();
  const firstAidItemsData = useAppSelector(firstAidItemsSelector);
  const [isSeedData, setSeedData] = useState<boolean>(false);
  const [isShowFirstAid, setIsShowFirstAid] = useState<boolean>(false);
  const [firstAidItemsPopupData, setFirstAidItemsPopupData] = useState<FirstAidItemsRes>();

  const onConfirm = () => {
    dispatch(getFirstAidSeedData());
    setSeedData(false);
  };

  const togglePopupFirstAidItems = (data: FirstAidItemsRes) => {
    setIsShowFirstAid(true);
    setFirstAidItemsPopupData(data);
    // show active or inactive
    const itemElement = document.getElementById(`item-${data.id}`);
    if (itemElement?.className.includes('item-active')) {
      itemElement?.classList.remove('item-active');
    } else {
      itemElement?.classList.add('item-active');
    }
  };

  const onCloseFirstAidItemsPopup = () => {
    const elmItem = document.querySelectorAll('.list-item');
    elmItem.forEach((elm) => {
      elm.classList.remove('item-active');
    });
    setIsShowFirstAid(false);
  };

  useEffect(() => {
    dispatch(getFirstAidItems());
  }, []);

  return (
    <div className="first-aid-items">
      <div className="card card-table">
        <div className="card-body">
          <div className="seed-data-group">
            <Text fontSize={18} fontWeight="700" color={Colors.LSNavy}>
              First Aid Tips
            </Text>
            <button className="seed-data" onClick={() => setSeedData(true)}>
              Seed Data
            </button>
          </div>
          <div className="card-title-group">
            <img src={images.FirstAidTips} alt="" />
            <div className="card-title">First Aid Tips</div>
            <Text fontSize={18} fontWeight="600" color={Colors.LSNavy}>
              Your guide to basic first aid addressing common emergency situations.
            </Text>
          </div>
          <ul className="list-group mt-3">
            {!firstAidItemsData.isLoading ? (
              firstAidItemsData.data &&
              firstAidItemsData.data.firstAidItems.length > 0 &&
              firstAidItemsData.data.firstAidItems.map((item) => (
                <div
                  id={`item-${item.id}`}
                  key={item.id}
                  className={'list-item'}
                  onClick={() => togglePopupFirstAidItems(item)}
                >
                  <Text color={Colors.LSNavy} fontSize={16} fontWeight="600">
                    {item.displayOrder}. {item.displayText}
                  </Text>
                  <div className="chevron-container">
                    <i className="bi bi-chevron-right"></i>
                  </div>
                </div>
              ))
            ) : (
              <div className="d-flex justify-content-center">
                <div className="loader-image">
                  <img src={images.Spinner} alt="" />
                </div>
              </div>
            )}
          </ul>
          {isShowFirstAid && (
            <FirstAidItemsPopup
              firstAidItemsPopupData={firstAidItemsPopupData}
              onClose={onCloseFirstAidItemsPopup}
            />
          )}
          {isSeedData && (
            <ModalConfirm
              onConfirm={onConfirm}
              title="Seed Data"
              content="The First Aid Tips data already exists, do you want to proceed?"
              onClose={() => setSeedData(false)}
            />
          )}
        </div>
      </div>
    </div>
  );
};
